import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker21() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Comment transformer son projet de soutenance en un produit international sans financement.</h5>
          <p>
            En 2004 j’ai soutenu mon BTS sur un système qui sert à contrôler les recettes dans les salles de jeux vidéo. A ce jour le système  est vendu dans plus de 25 pays sans financement.
            Switch SAB est le nom du système. A ce jour nous rendons plus de 300 jeunes financièrement autonomes en Afrique. Car ceux qui disposent des moyens pour investir veulent d’abord s’assurer de la sécurité de leur investissement avant de s’engager.
            Pour satisfaire les utilisateurs du Switch SAB j’ai dû trouver une formule pour rémunérer les membres d’une équipe qui travaille constamment sur l’amélioration du système.
            Le Bénin est un petit pays, très tôt le marché était saturé il fallait donc conquérir d’autres pays et pour cela il nous fallait trouver une meilleure solution pour que les clients payent à distance en toute sécurité, de sorte à rassurer le client. Dans le même temps produire un système exportable et aussi avoir un financement pour la production.
            Nous avons donc adopté le payement avant livraison. Il a fallu 10 ans avant de trouver la bonne approche pour que les clients acceptent cette méthode de payement.
            A ce jour notre impacte est à double titre :
            D’une part la découverte du switch SAB motive les investisseurs à investir dans le domaine des jeux vidéo donc à créer plus d’emploi.
            D’autre part nous avons atteint l’expertise où nous pouvons soutenir d’autres jeunes à partir d’un projet d’école et en faire une entreprise.
            Pendant la conférence vous aurez à découvrir comment nous avons franchi chaque étape.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker21;
