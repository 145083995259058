import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker20() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>DETECTION INTELLIGENTE DE PATHOLOGIES CARDIAQUES PAR LE PHONOCARDIOGRAMME </h5>
          <br />
          <p>
            Le contrôle de la santé humaine est de plus en plus observé de nos jours avec le développement des objets mobiles intelligents qui facilitent l’acquisition des signaux médicaux chez le patient pour évaluer l'efficacité des traitements et le suivi des patients malades. Des appareils mobiles usuels tels que des montres, des tablettes, des smartphones, etc., sont utilisés pour collecter des données médicales du patient. Ces mêmes appareils peuvent traiter les données recueillies et poser le diagnostic s’ils comportent des méthodes de traitement des données appropriées. Pour ce faire, le présent article propose une méthode de traitement dans le domaine temporel pour identifier les Sons cardiaques et détecter les bruits surajoutés dans le phonocardiogramme (PCG). Cette transformée, basée sur l’énergie du signal est appliquée au PCG pour mettre en exergue les bruits cardiaques. Les résultats obtenus avec la transformée proposée montrent une meilleure valeur prédictive positive 95% contre 63,63% obtenus  des méthodes de la littérature. Ce qui facilite l’identification de chaque type de son par la détection des pics (maxima).
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker20;
