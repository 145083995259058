import React from 'react';
import { Link } from 'react-router-dom';
import pub4 from '../../assets/images/FB_IMG_1701188366791.jpg';
import pub1 from '../../assets/images/pub1.jpg';
import pub2 from '../../assets/images/pub2.jpg';
import pub3 from '../../assets/images/pub3.jpg';
import shape5 from '../../assets/images/shape/5.png';
import shape12 from '../../assets/images/shape/shape-12.png';

function BlogHomeEight() {
    return (
      <>
        <section className="appie-blog-3-area appie-blog-8-area pt-90 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-section-title text-center">
                  <h3 className="appie-title">Nos récentes activités</h3>
                  <p>Allons à la découverte de nos récentes activités.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub3} alt="" className="pubcard" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/activites/infos3">Cérémonie d'ouverture</Link>
                    </h5>
                    <div className="meta-item">
                      <ul>
                        <li>
                          <i className="fal fa-clock"></i>
                          {' '}
                          10 Déc, 2023
                        </li>
                        <li>
                          <i className="fal fa-comments"></i>
                          {' '}
                          15 Déc, 2023
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub2} alt="" className="pubcard" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/activites/infos1">
                        Hackathon-JSTIM 2023
                      </Link>
                    </h5>
                    <div className="meta-item">
                      <ul>
                        <li>
                          <i className="fal fa-clock"></i>
                          {' '}
                          03 Dec, 2023
                        </li>
                        <li>
                          <i className="fal fa-comments"></i>
                          {' '}
                          03 Dec, 2023
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub1} alt="" className="pubcard" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/activites/infos2">Appel à communication</Link>
                    </h5>
                    <div className="meta-item">
                      <ul>
                        <li>
                          <i className="fal fa-clock"></i>
                          {' '}
                          03 Déc, 2023
                        </li>
                        <li>
                          <i className="fal fa-comments"></i>
                          {' '}
                          03 Déc, 2023
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub4} alt="" className="pubcard" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/activites/infos">Lancement officiel des JSTIM 2023</Link>
                    </h5>
                    <div className="meta-item">
                      <ul>
                        <li>
                          <i className="fal fa-clock"></i>
                          {' '}
                          03 Déc, 2023
                        </li>
                        <li>
                          <i className="fal fa-comments"></i>
                          {' '}
                          03 Déc, 2023
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="blog-btn text-center mt-60">
                  <Link to="/activites" className="main-btn">
                    Tout voir!!
                    {' '}
                    <i className="fal fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="appie-blog-8-shape-1">
            <img src={shape5} alt="" />
          </div>
          <div className="appie-blog-8-shape-2">
            <img src={shape12} alt="" />
          </div>
        </section>
      </>
    );
}

export default BlogHomeEight;
