import React from 'react';
import { Link } from 'react-router-dom';
import start from '../../assets/images/start.jpg';
import pdf from '../Calendars/ProgrammeJSTIM2023.pdf';

function DetailsService() {
    return (
      <>
        <section className="appie-service-details-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="service-details-sidebar mr-50">
                  <div className="service-category-widget">
                    <ul>
                      <li>
                        <Link to="/calendar">
                          <i className="fal fa-calendar"></i>
                          Programme de lancement du colloque
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/calendar">
                          <i className="fal fa-calendar"></i>
                          15 Décembre 2023,
                          UAC.
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/calendar">
                          <i className="fal fa-calendar"></i>
                          16 Décembre 2023,
                          Banikoara.
                        </Link>
                      </li>
                      <li>
                        <Link to="/calendar">
                          <i className="fal fa-calendar"></i>
                          17 Décembre 2023,
                          Banikoara.
                        </Link>
                      </li>
                      <li>
                        <Link to="/calendar">
                          <i className="fal fa-calendar"></i>
                          18 Décembre 2023,
                          Banikoara.
                        </Link>
                      </li>
                      <li>
                        <Link to="/calendar">
                          <i className="fal fa-calendar"></i>
                          19 Décembre 2023,
                          Banikoara.
                        </Link>
                      </li>
                      <li>
                        <Link to="/calendar">
                          <i className="fal fa-calendar"></i>
                          20 Décembre 2023,
                          Banikoara.
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                  <div className="service-download-widget">
                    <a href={pdf} target="_blank" rel="noreferrer" alt="Programme de lancement">
                      <i className="fal fa-download"></i>
                      <span>Télécharger le programme</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 md-5">
                <div className="service-details-content">
                  <div className="thumb">
                    <img src={start} alt="" />
                  </div>
                  {/* <div className="content">
                    <h3 className="title">08H - 10H, UAC.</h3>
                    <p>Cérémonie de lancement des JSTIM.</p>
                  </div>
                  <div className="content">
                    <h3 className="title">08H - 10H, UAC.</h3>
                    <p>Cérémonie de lancement des JSTIM.</p>
                  </div>
                  <div className="content">
                    <h3 className="title">08H - 10H, UAC.</h3>
                    <p>Cérémonie de lancement des JSTIM.</p>
                  </div>
                  <div className="content">
                    <h3 className="title">08H - 10H, UAC.</h3>
                    <p>Cérémonie de lancement des JSTIM.</p>
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="row justify-content-center">
              <div className="col-lg-12 mt-5">
                <div
                  className="testimonial-about-slider-active"
                  style={{ position: 'relative' }}
                >
                  <span
                    onClick={sliderPrev}
                    className="prev slick-arrow"
                    style={{ display: 'block' }}
                  >
                    <i className="fal fa-arrow-left"></i>
                  </span>
                  <Slider {...settingsForPub} ref={pubCarosel}>
                    <div className="testimonial-parent-item">
                      <div className=" col-lg-5 col-md-5">
                        <div
                          className="appie-team-item appie-team-item-about appie-team-item-8
                            mt-30
                            wow
                            animated
                            fadeInUp
                        "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="">
                            <img src={pub1} alt="" className="pubcard" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-parent-item">
                      <div className=" col-lg-5 col-md-5">
                        <div
                          className="appie-team-item appie-team-item-about appie-team-item-8
                            mt-30
                            wow
                            animated
                            fadeInUp
                        "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="">
                            <img src={pub2} alt="" className="pubcard" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-parent-item">
                      <div className=" col-lg-5 col-md-5">
                        <div
                          className="appie-team-item appie-team-item-about appie-team-item-8
                            mt-30
                            wow
                            animated
                            fadeInUp
                        "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="">
                            <img src={pub3} alt="" className="pubcard" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                  <span
                    onClick={sliderNext}
                    className="next slick-arrow"
                    style={{ display: 'block' }}
                  >
                    <i className="fal fa-arrow-right"></i>
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </>
    );
}

export default DetailsService;
