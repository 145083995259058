import React from 'react';
import useToggle from '../../Hooks/useToggle';
import HeaderNews from '../Activities/HeaderNews';
import HeroNews from '../Activities/HeroNews';
import BackToTop from '../BackToTop';
import FooterHomeEight from '../HomeEight/FooterHomeEight';
import Drawer from '../Mobile/Drawer';
import Forms from './Forms';

function Contact() {
    const [drawer, drawerAction] = useToggle(false);

    return (
      <>
        <Drawer drawer={drawer} action={drawerAction.toggle} />
        <HeaderNews drawer={drawer} action={drawerAction.toggle} />
        <HeroNews
          title="Participation"
          breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/news', title: 'Participation' },
                ]}
        />
        <Forms />
        <FooterHomeEight />
        <BackToTop />
      </>
    );
}

export default Contact;
