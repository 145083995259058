import React from 'react';
import BlogImg1 from '../../assets/images/pub2.jpg';
import BlogImg2 from '../../assets/images/pub1.jpg';
import BlogImg3 from '../../assets/images/pub3.jpg';
import BlogImg4 from '../../assets/images/FB_IMG_1701188366791.jpg';

function BlogSideBar() {
    return (
      <div className="blog-sidebar">
        <aside className="widget widget-search">
          {/* <form className="search-form" action="#" method="post">
            <input type="search" name="s" placeholder="Chercher..." />
            <button type="submit">
              <i className="fal fa-search"></i>
            </button>
          </form> */}
        </aside>
        <aside className="widget widget-categories">
          <h3 className="widget-title">Archives</h3>
          <ul>
            <li>
              <a href="#">1ère Edition</a>
              <span>(24)</span>
            </li>
            <li>
              <a href="#">2ème Edition</a>
              <span>(15)</span>
            </li>
            <li>
              <a href="#">3ème Edition</a>
              <span>(8)</span>
            </li>
          </ul>
        </aside>
        <aside className="widget widget-trend-post">
          <h3 className="widget-title">Activités récentes</h3>
          <div className="popular-post">
            <a href="#">
              <img src={BlogImg1} alt="" />
            </a>
            <h5>
              <a href="#">Hackathon-JSTIM 2023</a>
            </h5>
            <span>Dec 03, 2023</span>
          </div>
          <div className="popular-post">
            <a href="#">
              <img src={BlogImg2} alt="" />
            </a>
            <h5>
              <a href="#">Appel à communication</a>
            </h5>
            <span>Dec 14, 2023</span>
          </div>
          <div className="popular-post">
            <a href="#">
              <img src={BlogImg3} alt="" />
            </a>
            <h5>
              <a href="#">Lancement officiel des JSTIM</a>
            </h5>
            <span>Dec 14, 2023</span>
          </div>
          <div className="popular-post">
            <a href="#">
              <img src={BlogImg4} alt="" />
            </a>
            <h5>
              <a href="#">Cérémonie d'ouverture</a>
            </h5>
            <span>Dec 13, 2023</span>
          </div>
        </aside>
        <aside className="widget">
          <h3 className="widget-title">Thèmes fréquents</h3>
          <div className="tags">
            <a href="#">Technologies</a>
            <a href="#">Maths</a>
            <a href="#">Physiques</a>
            <a href="#">Jeunes</a>
            <a href="#">JSTIM</a>
            <a href="#">IA</a>
            <a href="#">Sciences</a>
            <a href="#">Internet</a>
            <a href="#">Education</a>
            <a href="#">Innovation</a>
          </div>
        </aside>
      </div>
    );
}

export default BlogSideBar;
