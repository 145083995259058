import React from 'react';
import heroThumb from '../../assets/images/hero-thumb-5.png';
import HeroDot from '../../assets/images/hero-dot.png';

function ServicesAbout() {
    return (
      <>
        <section className="appie-services-2-area pt-90 pb-55" id="service">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-6 col-md-8">
                <div className="appie-section-title">
                  <div className="col-lg-7">
                    <div className="appie-hero-thumb appie-hero-thumb-4">
                      <img src={heroThumb} alt="" />
                      <div className="hero-dot">
                        <img src={HeroDot} alt="" />
                      </div>
                    </div>
                  </div>
                  <h5 className="appie-title">Pourquoi participer à la JSTIM?</h5>
                  <p>
                    Vous êtes jeunes, passionnés par le numérique,
                    vous êtes un acteur du digital...
                    {' '}
                    <br />
                    <p>
                      Voici les 3 raisons pour prendre part à la JSTIM 2023.
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i className="fal fa-tv"></i>
                  </div>
                  <h4 className="title">Actualité digitale</h4>
                  <p>Les thèmes de discussion autour de l'actualité numérique.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 appie-single-service-about item-2 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <div className="icon">
                    <i className="fal fa-code"></i>
                  </div>
                  <h4 className="title">Innovation scientifique</h4>
                  <p>Découvertes de nouveaux projets et des opportunités. </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 appie-single-service-about item-3 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <div className="icon">
                    <i className="fal fa-user-friends"></i>
                  </div>
                  <h4 className="title">Networking</h4>
                  <p>Des séances d'orientation et de réseautage.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

export default ServicesAbout;
