import React from 'react';
// import of animations;
import { Bounce, Roll } from 'react-awesome-reveal';

// import of shape
import shapeOne from '../../assets/images/shape/1.png';
import shapeTwo from '../../assets/images/shape/2.png';
import shapeThree from '../../assets/images/shape/3.png';
import shapeFour from '../../assets/images/shape/4.png';

function HeroHomeEight() {
    return (
      <>
        <section className="appie-hero-area appie-hero-8-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="appie-hero-content appie-hero-content-8">
                  <Bounce left>
                    <h1 className="appie-title">JSTIM 2023</h1>
                    <p className="size-of-text">
                      <span className="initial-different">J</span>
                      ournées des
                      <span className="initial-different1 end"> S</span>
                      ciences
                      <span className="initial-different2">T</span>
                      echnolgies,
                      <br />
                      <span className="initial-different3">I</span>
                      ngénierie et
                      <span className="initial-different4 end"> M</span>
                      athématiques.
                    </p>
                  </Bounce>
                  <ul>
                    <Roll>
                      <li>
                        <a href="/programme">
                          <i className="fa-regular fa-calendar-plus icons-style" />
                          <span>Décembre 2023</span>
                        </a>
                      </li>
                    </Roll>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="appie-hero-thumb-6">
                  <div
                    className="thumb text-center wow animated fadeInUp"
                    data-wow-duration="1000ms"
                    data-wow-delay="600ms"
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-8-shape-1">
            <img src={shapeThree} alt="" />
          </div>
          <div className="home-8-shape-2">
            <img src={shapeFour} alt="" />
          </div>
          <div className="home-8-shape-3">
            <img src={shapeOne} alt="" />
          </div>
          <div className="home-8-shape-4">
            <img src={shapeTwo} alt="" />
          </div>
        </section>
      </>
    );
}

export default HeroHomeEight;
