import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker15() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Évaluation de l'efficacité d'un indice de stabilité de tension pour l'identification des nœuds et lignes vulnérables dans un réseau de transport d'énergie électrique. </h5>
          <p>
            Dans le cadre de l’écoconstruction des ouvrages de génie civil, des travaux sont réalisés pour identifier et éliminer les matériaux ayant un mauvais impact sur l’environnement. Le béton de ciment ou plus précisément le ciment portland a été identifié comme matériaux très nuisible avec des taux d’émission de gaz à effet de serre allant jusqu’à 8% des émissions annuelles de CO2 du secteur de la construction. Les travaux réalisés par les chercheurs ont montrés que les bétons géopolymères ou encore les ciments géopolymères constituent de très bonnes alternatives au ciment portland. Des études plus spécifiques au contexte africain ont permis de démontrer qu’il est aussi possible de concevoir les géoplymères en intégrant des déchets agricoles et d’obtenir des matériaux de très bonnes résistances comparativement au béton de ciment. Ainsi des travaux de conception de bétons de géoplymères sont réalisés en intégrant aux ingrédients classiques les cendres de balles (écorce) de riz. <br />
            Cependant, dans ces différents travaux, la question de la production à l’échelle industrielle  n’est  généralement pas abordée. La production à grande échelle de liant géopolymère nécessite une maitrise parfaite de la production des ingrédients de base : le traitement thermique des argiles et la calcination des déchets agricoles. L’objectif de ces travaux est de mettre en place un circuit permettant d’obtenir ces ingrédients avec la meilleure qualité possible. Pour y arriver nous étudions successivement les vitesses de calcination, les températures limites et les diamètres des particules. S’ensuivent les conceptions architecturale, mécanique et énergétique du circuit.

          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker15;
