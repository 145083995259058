import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeEight from '../HomeEight/FooterHomeEight';
import Drawer from '../Mobile/Drawer';
// import BlogSideBar from './BlogSideBar';
import HeaderNews from './HeaderNews';
import HeroNews from './HeroNews';
import Panels2 from './Panels2';

function Panel2() {
    const [drawer, drawerAction] = useToggle(false);
    return (
      <>
        <Drawer drawer={drawer} action={drawerAction.toggle} />
        <HeaderNews action={drawerAction.toggle} />
        <HeroNews
          title="A la découverte de la JSTIM 2023"
          breadcrumb={[
            { link: '/', title: 'Accueil' },
            { link: '/JSTIM2023', title: 'Panels retenus' },
                ]}
        />
        <section className="blogpage-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-7">
                <Panels2 />
              </div>
            </div>
          </div>
        </section>
        <FooterHomeEight />
        <BackToTop />
      </>
    );
}

export default Panel2;
