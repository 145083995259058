import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import AboutUsTwo from './components/AboutUs/AboutUsTwo';
import Activities from './components/Activities';
import Infos1 from './components/Activities/Speaker1/Infos1';
import Infos10 from './components/Activities/Speaker10/Infos10';
import Infos11 from './components/Activities/Speaker11/Infos11';
import Infos12 from './components/Activities/Speaker12/Infos12';
import Infos13 from './components/Activities/Speaker13/Infos13';
import Infos14 from './components/Activities/Speaker14/Infos14';
import Infos15 from './components/Activities/Speaker15/Infos15';
import Infos16 from './components/Activities/Speaker16/Infos16';
import Infos17 from './components/Activities/Speaker17/Infos17';
import Infos18 from './components/Activities/Speaker18/Infos18';
import Infos19 from './components/Activities/Speaker19/Infos19';
import Infos20 from './components/Activities/Speaker20/Infos20';
import Infos21 from './components/Activities/Speaker21/Infos21';
import Infos22 from './components/Activities/Speaker22/Infos22';
import Infos23 from './components/Activities/Speaker23/Infos23';
import Infos24 from './components/Activities/Speaker24/Infos24';
import Infos25 from './components/Activities/Speaker25/Infos25';
import Infos26 from './components/Activities/Speaker26/Infos26';
import Infos27 from './components/Activities/Speaker27/Infos27';

import Infos3 from './components/Activities/Speaker3/Infos3';
import Infos4 from './components/Activities/Speaker4/Infos4';
import Infos5 from './components/Activities/Speaker5/Infos5';
import Infos6 from './components/Activities/Speaker6/Infos6';
import Infos7 from './components/Activities/Speaker7/Infos7';
import Infos8 from './components/Activities/Speaker8/Infos8';
import Infos9 from './components/Activities/Speaker9/Infos9';

import News1 from './components/Activities/News1';
import News2 from './components/Activities/News2';
import News3 from './components/Activities/News3';
import Panel from './components/Activities/Panel';
import SingleNews from './components/Activities/SingleNews';
import Speaker1 from './components/Activities/Speaker1/Speaker1';
import Speaker10 from './components/Activities/Speaker10/Speaker10';
import Speaker11 from './components/Activities/Speaker11/Speaker11';
import Speaker12 from './components/Activities/Speaker12/Speaker12';
import Speaker13 from './components/Activities/Speaker13/Speaker13';
import Speaker14 from './components/Activities/Speaker14/Speaker14';
import Speaker15 from './components/Activities/Speaker15/Speaker15';
import Speaker16 from './components/Activities/Speaker16/Speaker16';
import Speaker17 from './components/Activities/Speaker17/Speaker17';
import Speaker18 from './components/Activities/Speaker18/Speaker18';
import Speaker19 from './components/Activities/Speaker19/Speaker19';
import Speaker20 from './components/Activities/Speaker20/Speaker20';
import Speaker21 from './components/Activities/Speaker21/Speaker21';
import Speaker22 from './components/Activities/Speaker22/Speaker22';
import Speaker23 from './components/Activities/Speaker23/Speaker23';
import Speaker24 from './components/Activities/Speaker24/Speaker24';
import Speaker25 from './components/Activities/Speaker25/Speaker25';
import Speaker26 from './components/Activities/Speaker26/Speaker26';
import Speaker27 from './components/Activities/Speaker27/Speaker27';

import Speaker3 from './components/Activities/Speaker3/Speaker3';
import Speaker4 from './components/Activities/Speaker4/Speaker4';
import Speaker5 from './components/Activities/Speaker5/Speaker5';
import Speaker6 from './components/Activities/Speaker6/Speaker6';
import Speaker7 from './components/Activities/Speaker7/Speaker7';
import Speaker8 from './components/Activities/Speaker8/Speaker8';
import Speaker9 from './components/Activities/Speaker9/Speaker9';

import Panel1 from './components/Activities/Panel1';
import Panel2 from './components/Activities/Panel2';
import Contact from './components/Contact';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeEight from './components/HomeEight';
import Programme from './components/Programme';
import Shops from './components/Shops';
import ShopDetails from './components/Shops/Details';

function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
      <>
        {loading && (
        <div className={`appie-loader ${loading ? 'active' : ''}`}>
          <Loader />
        </div>
            )}
        <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
          <Router>
            <ScrollToTop>
              <Switch>
                <Route exact path="/" component={HomeEight} />
                <Route exact path="/activites" component={Activities} />
                <Route exact path="/activites/infos" component={SingleNews} />
                <Route exact path="/activites/infos1" component={News1} />
                <Route exact path="/activites/infos2" component={News2} />
                <Route exact path="/activites/infos3" component={News3} />
                <Route exact path="/programme" component={Programme} />
                <Route exact path="/about-us" component={AboutUs} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/Felicitations" component={Error} />
                <Route exact path="/about-us-another" component={AboutUsTwo} />
                <Route exact path="/shops" component={Shops} />
                <Route exact path="/JSTIM2023" component={Panel} />
                <Route exact path="/shops/shop-details" component={ShopDetails} />
                <Route exact path="/speaker" component={Speaker1} />
                <Route exact path="/speaker8" component={Speaker8} />
                <Route exact path="/speaker3" component={Speaker3} />
                <Route exact path="/speaker4" component={Speaker4} />
                <Route exact path="/speaker5" component={Speaker5} />
                <Route exact path="/speaker6" component={Speaker6} />
                <Route exact path="/speaker7" component={Speaker7} />
                <Route exact path="/speaker9" component={Speaker9} />
                <Route exact path="/speaker10" component={Speaker10} />
                <Route exact path="/speaker11" component={Speaker11} />
                <Route exact path="/speaker12" component={Speaker12} />
                <Route exact path="/speaker13" component={Speaker13} />
                <Route exact path="/speaker14" component={Speaker14} />
                <Route exact path="/speaker15" component={Speaker15} />
                <Route exact path="/speaker16" component={Speaker16} />
                <Route exact path="/speaker17" component={Speaker17} />
                <Route exact path="/speaker18" component={Speaker18} />
                <Route exact path="/speaker19" component={Speaker19} />
                <Route exact path="/speaker20" component={Speaker20} />
                <Route exact path="/speaker21" component={Speaker21} />
                <Route exact path="/speaker22" component={Speaker22} />
                <Route exact path="/speaker23" component={Speaker23} />
                <Route exact path="/speaker24" component={Speaker24} />
                <Route exact path="/speaker25" component={Speaker25} />
                <Route exact path="/speaker26" component={Speaker26} />
                <Route exact path="/speaker27" component={Speaker27} />

                <Route exact path="/speaker-infos1" component={Infos1} />
                <Route exact path="/speaker-infos8" component={Infos8} />
                <Route exact path="/speaker-infos3" component={Infos3} />
                <Route exact path="/speaker-infos4" component={Infos4} />
                <Route exact path="/speaker-infos5" component={Infos5} />
                <Route exact path="/speaker-infos6" component={Infos6} />
                <Route exact path="/speaker-infos7" component={Infos7} />
                <Route exact path="/speaker-infos9" component={Infos9} />
                <Route exact path="/speaker-infos10" component={Infos10} />
                <Route exact path="/speaker-infos11" component={Infos11} />
                <Route exact path="/next" component={Panel1} />
                <Route exact path="/next1" component={Panel2} />

                <Route exact path="/speaker-infos12" component={Infos12} />
                <Route exact path="/speaker-infos13" component={Infos13} />
                <Route exact path="/speaker-infos14" component={Infos14} />
                <Route exact path="/speaker-infos15" component={Infos15} />
                <Route exact path="/speaker-infos16" component={Infos16} />
                <Route exact path="/speaker-infos17" component={Infos17} />
                <Route exact path="/speaker-infos18" component={Infos18} />
                <Route exact path="/speaker-infos19" component={Infos19} />
                <Route exact path="/speaker-infos20" component={Infos20} />
                <Route exact path="/speaker-infos21" component={Infos21} />
                <Route exact path="/speaker-infos22" component={Infos22} />
                <Route exact path="/speaker-infos23" component={Infos23} />
                <Route exact path="/speaker-infos24" component={Infos24} />
                <Route exact path="/speaker-infos25" component={Infos25} />
                <Route exact path="/speaker-infos26" component={Infos26} />
                <Route exact path="/speaker-infos27" component={Infos27} />

                <Route component={Error} />
              </Switch>
            </ScrollToTop>
          </Router>
        </div>
      </>
    );
}

export default Routes;
