import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker25() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Analyse des séries temporelles des variables climatiques sur les ressources en eau dans la haute vallée de l'Ouémé à Bétérou à l'aide du modèle saisonnière ARIMA.</h5>
          <br />
          <p>
            La structure dynamique du climat est régie par les changements de précipitations et de températures, elle peut être étudiée par l'analyse des séries temporelles de ces deux facteurs.
            Les données utilisées sont celles de l'année 1982-2022. Le modèle Autorégressif (p) Intégré (d) à Moyenne mobile (q) (ARIMA) utilisé est basé sur l'approche de Box Jenkins qui prévoit les tendances futures en rendant les données stationnaires et en supprimant la saisonnalité.
            Les résultats de l'étude montrent que la température est élevée pendant la saison sèche, lorsque les précipitations sont faibles. En outre, le modèle saisonnier ARIMA correspondant le mieux aux précipitations et aux températures est représenté respectivement par ARIMA (5, 1, 0)(2, 0, 0)12 et ARIMA (2, 1, 1)(1, 0, 1)12. Une prévision a été faite pour les 30 prochaines années (2015-2045).
            Les résultats de la prédiction du modèle montrent que les données prévues correspondent bien avec la tendance des données. Une telle analyse approfondie pourrait contribuer à l'élaboration de politiques et de stratégies de gestion de l'eau adaptées aux réalités changeantes du climat, tout en assurant une utilisation durable des ressources en eau dans le bassin du fleuve Ouémé à Bétérou.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker25;
