import React from 'react';
import blogImg1 from '../../assets/images/JSTIM2022_1.jpg';
import blogImg2 from '../../assets/images/blog1.webp';
import blogImg3 from '../../assets/images/blog2.jpg';
import blogImg4 from '../../assets/images/blog3.jpg';

function Blogs() {
    return (
      <>
        <div className="row">
          <div className="col-lg-6">
            <div className="post-item-1">
              <img src={blogImg3} alt="" />
              <div className="b-post-details">
                <div className="bp-meta">
                  <a href="#">
                    <i className="fal fa-clock"></i>
                    Sept, 2014
                  </a>
                  <a href="#">
                    <i className="fal fa-comments"></i>
                    6 Commentaires
                  </a>
                </div>
                <h3>
                  <a href="#">
                    Vulgarisation des sciences mathématiques.
                  </a>
                </h3>
                {/* <a className="read-more" href="single-post.html">
                  Lire plus.
                  <i className="fal fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="post-item-1">
              <img src={blogImg2} alt="" />
              <div className="b-post-details">
                <div className="bp-meta">
                  <a href="#">
                    <i className="fal fa-clock"></i>
                    Mars, 2015
                  </a>
                  <a href="#">
                    <i className="fal fa-comments"></i>
                    6 Commentaires
                  </a>
                </div>
                <h3>
                  <a href="#">
                    Promotions des sciences mathématiques.
                  </a>
                </h3>
                {/* <a className="read-more" href="single-post.html">
                  Lire plus.
                  <i className="fal fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="post-item-1">
              <img src={blogImg1} alt="" />
              <div className="b-post-details">
                <div className="bp-meta">
                  <a href="#">
                    <i className="fal fa-clock"></i>
                    Dec 12, 2022
                  </a>
                  <a href="#">
                    <i className="fal fa-comments"></i>
                    6 Commentaires
                  </a>
                </div>
                <h3>
                  <a href="#">
                    Tournées dans les CEGs.
                  </a>
                </h3>
                {/* <a className="read-more" href="single-post.html">
                  Lire plus.
                  <i className="fal fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="post-item-1">
              <img src={blogImg4} alt="" />
              <div className="b-post-details">
                <div className="bp-meta">
                  <a href="#">
                    <i className="fal fa-clock"></i>
                    Dec, 2022
                  </a>
                  <a href="#">
                    <i className="fal fa-comments"></i>
                    6 Commentaires
                  </a>
                </div>
                <h3>
                  <a href="#">
                    JSTIM, 2ème Edition.
                  </a>
                </h3>
                {/* <a className="read-more" href="single-post.html">
                  Lire plus.
                  <i className="fal fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-12">
            <div className="bisylms-pagination">
              <span className="current">01</span>
              <a href="#">02</a>
              <a className="next" href="#">
                next
                <i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div> */}
      </>
    );
}

export default Blogs;
