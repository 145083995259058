import React from 'react';
import singlePost from '../../assets/images/FB_IMG_1701188366791.jpg';

function Blog() {
    return (
      <>
        <div className="single-post-area">
          <p>
            𝗦𝗮𝗹𝘂𝘁 𝗰𝗵𝗲̀𝗿𝗲 𝗰𝗼𝗺𝗺𝘂𝗻𝗮𝘂𝘁𝗲́,
            Pour prendre part à la troisième édition des Journées des Sciences, Technologies, Ingénierie et Mathématiques (JSTIM), 𝘃𝗲𝘂𝗶𝗹𝗹𝗲𝘇 𝗻𝗼𝘂𝘀 𝗳𝗮𝗶𝗿𝗲 𝗽𝗮𝗿𝘃𝗲𝗻𝗶𝗿 𝘃𝗼𝘁𝗿𝗲 𝗻𝗼𝗺, 𝗽𝗿𝗲́𝗻𝗼𝗺𝘀, 𝗲𝘁 𝘂𝗻𝗲 𝗽𝗵𝗼𝘁𝗼 𝗱𝗲 𝘃𝗼𝘂𝘀 𝗮̀ 𝗹'𝗮𝗱𝗿𝗲𝘀𝘀𝗲 𝗲-𝗺𝗮𝗶𝗹 𝘀𝘂𝗶𝘃𝗮𝗻𝘁𝗲 : latipiitech27@gmail.com.
          </p>
          <div className="post-thumb">
            <img src={singlePost} alt="" />
          </div>
          <h4 className="article-title">Les JSTIM à votre portée.</h4>
          <p>
            <span role="img" aria-label="">🕑</span>
            {' '}
            𝑫𝒂𝒕𝒆 : 13 𝒂𝒖 20 𝒅é𝒄𝒆𝒎𝒃𝒓𝒆 2023
            {' '}
            <br />
            <span role="img" aria-label="">🚴‍♂</span>
            𝑳𝒊𝒆𝒖 : 𝑳'𝑼𝒏𝒊𝒗𝒆𝒓𝒔𝒊𝒕é 𝒅'𝑨𝒃𝒐𝒎𝒆𝒚 𝑪𝒂𝒍𝒂𝒗𝒊 & 𝑩𝒂𝒏𝒊𝒌𝒐𝒂𝒓𝒂
            <br />
            <span role="img" aria-label=""> 🔗</span>
            {' '}
            Cela nous permettra de créer votre affiche personnalisée indiquant votre participation à l'événement.
            𝙉𝙤𝙪𝙨 𝙨𝙤𝙢𝙢𝙚𝙨 𝙞𝙢𝙥𝙖𝙩𝙞𝙚𝙣𝙩𝙨 𝙙𝙚 𝙫𝙤𝙪𝙨 𝙞𝙣𝙘𝙡𝙪𝙧𝙚 𝙙𝙖𝙣𝙨 𝙣𝙤𝙩𝙧𝙚 𝙜𝙖𝙡𝙚𝙧𝙞𝙚 "𝙅'𝙮 𝙥𝙖𝙧𝙩𝙞𝙘𝙞𝙥𝙚" !

            {' '}
            <br />
          </p>
          <blockquote>
            <p>
              Une journée avec un enseignant illustre vaut mieux que mille jours d'étude assidue.
            </p>
            <cite>Inédit</cite>
          </blockquote>

          <div className="post-tags">
            <h5>Tags:</h5>
            <a href="#">𝑱𝑺𝑻𝑰𝑴2023</a>
            <a href="#"> É𝒅𝒖𝒄𝒂𝒕𝒊𝒐𝒏𝑻𝒆𝒄𝒉𝒏𝒐𝒍𝒐𝒈𝒊𝒒𝒖𝒆</a>
            <a href="#">𝑰𝒏𝒏𝒐𝒗𝒂𝒕𝒊𝒐𝒏 </a>
            <a href="#"> 𝑰𝑨𝒆𝒕É𝒅𝒖𝒄𝒂𝒕𝒊𝒐𝒏 </a>
            {' '}
            <br />
            <a href="#"> 𝑺𝒐𝒚𝒆𝒛𝑫𝒆𝒔𝑵ô𝒕𝒓𝒆𝒔 </a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>
          {/* <div className="post-admin">
            <img src={author} alt="" />
            <a href="#">Justin Case</a>
            <span>About Author</span>
            <p>
              Me old mucker bits and bobs you mug tickety-boo a load of old tosh bender
              mufty bloke old daft.
            </p>
          </div> */}
        </div>
        {/* <div className="comment-area">
          <h3>Comment (3)</h3>
          <ol className="comment-list">
            <li>
              <div className="single-comment">
                <img src={cmnt1} alt="" />
                <h5>
                  <a href="#">Jason Response</a>
                </h5>
                <span>April 03, 2019</span>
                <div className="comment">
                  <p>
                    He legged it bevvy mush owt to do with me such fibber fa about
                    cup of tea sloshed morish butty bubble. Butty gormless lavatory
                    twit bits and bobs pardon you daft ummm I'm telling.
                  </p>
                </div>
                <a className="comment-reply-link" href="#">
                  <i className="fal fa-reply"></i>
                  Reply
                </a>
                <div className="c-border"></div>
              </div>
              <ul className="children">
                <li>
                  <div className="single-comment">
                    <img src={cmnt2} alt="" />
                    <h5>
                      <a href="#">Sue Shei</a>
                    </h5>
                    <span>April 14, 2019</span>
                    <div className="comment">
                      <p>
                        Super chancer cheeky bloke haggle give us a bell well
                        mufty chinwag say bite. lavatory twit bits and bobs
                        pardon you daft ummm I'm telling. Blatant matie boy say
                        bugger all mate butty gormless
                      </p>
                    </div>
                    <a className="comment-reply-link" href="#">
                      <i className="fal fa-reply"></i>
                      Reply
                    </a>
                    <div className="c-border"></div>
                  </div>
                </li>
                <li>
                  <div className="single-comment">
                    <img src={cmnt3} alt="" />
                    <h5>
                      <a href="#">Douglas Lyphe</a>
                    </h5>
                    <span>July 27, 2020</span>
                    <div className="comment">
                      <p>
                        Oxford baking cakes dropped clanger fanny around
                        vagabond amongst cor blimey guvnor. Blatant matie boy
                        say bugger all mate butty gormless lavatory twit bits
                        and bobs pardon you daft ummm I'm telling.
                      </p>
                    </div>
                    <a className="comment-reply-link" href="#">
                      <i className="fal fa-reply"></i>
                      Reply
                    </a>
                    <div className="c-border"></div>
                  </div>
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div className="comment-form-area">
          <h3>Post a Comment</h3>
          <p>Your email address will not be published. Required fields are marked</p>
          <div className="comment-form">
            <form className="row" action="#" method="post">
              <div className="col-md-6">
                <input type="text" name="name" placeholder="Name" />
              </div>
              <div className="col-md-6">
                <input type="email" name="email" placeholder="Email" />
              </div>
              <div className="col-md-12">
                <input type="url" name="url" placeholder="Website" />
              </div>
              <div className="col-md-12">
                <div className="save-comment">
                  <input id="history-name" type="checkbox" />
                  <label htmlFor="history-name">
                    Save my name, email, and website in this browser for the next
                    time I comment.
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <textarea placeholder="Coment"></textarea>
              </div>
              <div className="col-md-12">
                <button type="submit">Post Comment</button>
              </div>
            </form>
          </div>
        </div> */}
      </>
    );
}

export default Blog;
