import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker9() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Equilibre offre demande en 2032 du réseau interconnecté du WAPP. </h5>
          <p>
            La répartition inégale des ressources énergétiques pour la production de l’énergie électrique dans l’espace géographique ouest africain, a conduit à créer le système d’Echange d’Energie Electrique Ouest Africain (EEEOA). Ce système permettra de mettre en place un marché commun de l’énergie électrique pour satisfaire l’équilibre entre l’offre et la demande à un prix abordable à partir d’un réseau interconnecté.
            Pour une adéquation offre et demande, la prévision de la pointe de demande électrique s’avère indispensable. Ce travail compare les performances de trois modèles de séries chronologiques (i) la moyenne mobile intégrée auto-régressive (ARIMA), (ii) le réseau neuronal récurrent à mémoire à court terme (LSTM RNN) et (iii) les Modèles Facebook (Fb) Prophet pour prédire la pointe annuelle.  Une actualisation de la capacité de production dans la Mise à jour du plan directeur révisé de la CEDEAO pour le développement de la capacité de production et de transport d’énergie électrique 2018 à été faite afin d’établir l’adéquation offre demande. Aussi Il ressort que le Modèle Prophet Facebook (Fb) s’avère la meilleures avec une faible erreur quadratique moyenne (RMSE 1,225 GW), une erreur absolue moyenne en pourcentage (MAPE, 3,1%) et une pointe de 13.62GW en 2032. Un surinvestissement dans les moyens de production a été constaté mais l’équilibre offre et demande a été satisfaite.

          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />
            <a href="#"></a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker9;
