import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import HeaderAbout from './HeaderAbout';
// import HeroAbout from './HeroAbout';
import ServicesAbout from './ServicesAbout';
import FooterHomeEight from '../HomeEight/FooterHomeEight';

function AboutUs() {
    const [drawer, drawerAction] = useToggle(false);

    return (
      <>
        <Drawer drawer={drawer} action={drawerAction.toggle} />
        <HeaderAbout drawer={drawer} action={drawerAction.toggle} />
        {/* <HeroAbout /> */}
        <ServicesAbout />
        <FooterHomeEight />
        <BackToTop />
      </>
    );
}

export default AboutUs;
