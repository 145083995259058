import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker23() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Contribution de l'intelligence artificielle à la détection précoce du Diabète par l'analyse de l’odeur corporelle.</h5>
          <br />
          <p>
            In the era of technology, artificial intelligence (AI) has tremendously impacted the learning of foreign languages. Via Duolingo Artificial Intelligence has transformed the way people learn and develop their language competences. Students learning English or French at CEBELAE use Duolingo in order to master or improve their foreign language skills. The objective of this study is to identify the impact of Duolingo in improving foreign language learning at CEBELAE. Data were collected using qualitative methods. The findings of this study show the impact and the advantages of AI-driven Duolingo in learning foreign language as fast as possible.

          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#">Duolingo</a>
            <a href="#">AI</a>
            <a href="#">CEBELAE</a>
            <a href="#">Foreign language</a>

            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker23;
