import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeEight from '../HomeEight/FooterHomeEight';
import Drawer from '../Mobile/Drawer';
import Blog from './Blog';
import BlogSideBar from './BlogSideBar';
import HeaderNews from './HeaderNews';
import HeroNews from './HeroNews';

function SingleNews() {
    const [drawer, drawerAction] = useToggle(false);
    return (
      <>
        <Drawer drawer={drawer} action={drawerAction.toggle} />
        <HeaderNews action={drawerAction.toggle} />
        <HeroNews
          title="Blog"
          breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/archives', title: 'Blogs' },
                    { link: '/activités/archives', title: 'Blog' },
                ]}
        />
        <section className="blogpage-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-7">
                <Blog />
              </div>
              <div className="col-lg-4 col-md-5">
                <BlogSideBar />
              </div>
            </div>
          </div>
        </section>
        <FooterHomeEight />
        <BackToTop />
      </>
    );
}

export default SingleNews;
