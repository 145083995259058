import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker10() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Optimisation de la gestion des ressources humaines éducatives au Bénin grâce à l'Intelligence Artificielle. </h5>
          <br />
          <p>
            Dans le contexte de l'amélioration continue du système éducatif au Bénin, cette communication présente une approche novatrice axée sur l'utilisation d'EducMaster, une plateforme web dédiée au pilotage et à la gestion efficace de l'éducation. Notre étude se concentre particulièrement sur la gestion numérique des absences au poste des enseignants. Les objectifs visent à garantir une présence régulière du personnel éducatif, renforçant ainsi la qualité de l'enseignement. La méthodologie adoptée implique une intégration étroite d'EducMaster avec des systèmes d'intelligence artificielle, permettant aux responsables éducatifs, des établissements aux niveaux départementaux et ministériels, de suivre en temps réel les présences au poste des enseignants. Les résultats préliminaires démontrent une amélioration significative de la gestion des ressources humaines éducatives, favorisant un environnement propice à l'apprentissage. Cette approche offre une solution efficiente pour répondre aux défis liés à la présence du personnel enseignant.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#">GestionDesRessourcesHumaines</a>
            <a href="#">PrésenceAuPoste</a>
            <a href="#">EducMaster</a>
            <a href="#">SystèmeEducatifBéninois</a>
          </div>
          <br />
          <h4>IN ENGLISH</h4>
          <br />
          <h5>Enhancing Human Resource Management in Benin's Education System through Artificial Intelligence </h5>
          <br />
          <p>
            In the context of continuous improvement in Benin's education system, this presentation introduces an innovative approach centered around the use of EducMaster, a web platform dedicated to the effective management of education. The focus of our study lies in the digital management of teachers' attendance. The objectives aim to ensure regular presence of educational staff, thereby enhancing the quality of education. The methodology involves close integration of EducMaster with artificial intelligence systems, enabling educational authorities, from individual institutions to departmental and ministerial levels, to monitor teachers' attendance in real-time. Preliminary results demonstrate a significant improvement in educational human resource management, fostering an environment conducive to learning. This approach provides an efficient solution to address challenges related to teacher presence.
          </p>
          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#">TeacherAttendance</a>
            <a href="#">BeninEducationSystem</a>
            <a href="#">EducMaster</a>
            <a href="#">HumanResourceManagement</a>
          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker10;
