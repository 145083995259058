import React from 'react';
import { Reveal, Slide } from 'react-awesome-reveal';
import activities from '../../assets/images/Exams-rafiki.png';
import orientation from '../../assets/images/Learning-rafiki.png';
// import animations

function AboutHomeEight() {
    return (
      <>
        <section className="appie-about-8-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Slide>
                  <div className="appie-section-title mb-30">
                    <h3 className="appie-title">
                      Nous encourageons la jeunesse béninoise
                      {' '}
                      <br />
                      à s'insérer dans le secteur du numérique
                      {' '}
                      <br />
                      de manière consciente.
                    </h3>
                  </div>
                </Slide>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="appie-about-8-box">
                  <h3 className="title">
                    Activités d'orientation
                    {' '}
                    <br />
                    scientifiques et technologiques
                  </h3>
                  <p className="activities-text">
                    Communiquer, discuter et pratiquer autour
                    {' '}
                    <br />
                    des sciences mathématiques.
                  </p>
                  {/* <a className="main-btn" href="#">
                    Voir plus
                    {' '}
                    <i className="fal fa-arrow-right" />
                  </a> */}
                  <div className="activities-img">
                    <img src={activities} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <Reveal>
                  <div className="appie-about-8-box">
                    <h3 className="title">
                      Secteurs d'activités
                      {' '}
                      <br />
                      {' '}
                      de la JSTIM
                    </h3>
                    <p>
                      {' '}
                      L'Informatique en générale, la robotique, l'Intelligence
                      secteurs clés du développement numérique.
                    </p>
                    {/* <a className="main-btn" href="#">
                    Voir plus
                    {' '}
                    <i className="fal fa-arrow-right" />
                  </a> */}
                    <div className="activities-img">
                      <img src={orientation} alt="" />
                    </div>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

export default AboutHomeEight;
