import React from 'react';
import { Link } from 'react-router-dom';
import pub1 from '../../assets/images/publications/comm1.jpg';
import pub2 from '../../assets/images/publications/comm2.png';
import pub3 from '../../assets/images/publications/comm3.png';
import pub4 from '../../assets/images/publications/comm4.png';
import pub5 from '../../assets/images/publications/comm5.jpeg';
import pub6 from '../../assets/images/publications/comm6.jpg';
import pub7 from '../../assets/images/publications/comm7.jpg';
import pub8 from '../../assets/images/publications/comm8.jpeg';

function Panels() {
    return (
      <>
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-section-title text-center">
                  <h3 className="appie-title">Nos speakers</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub1} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos1">Nonlinear Mixed Models (NLMMs), Appropriate approach for a robust infectious diseases modeling (IDM)</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>ADEOTI Olaiya Mathilde - LABEF/EDSAE/UAC, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos1">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub2} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos6">On graceful and cordial graph labelings</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>YEBADOKPO Charmaine Aurette O. A. - African Institute of Mathematical Sciences (AIMS-Senegal), Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos6">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub3} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos3">Etude de l’influence du taux de compactage sur la conductivité thermique d’un matériau routier granulaire non lié : cas de la grave latéritique.</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>ADJAGBONI Constant Euloge - Ecole Doctorale desSciences de l'Ingénieur, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos3">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub4} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos4">Étude de la prévalence des hémorroïdes au Bénin </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>YOMAKOU BATO GEORGES Gbénou René - EDSA, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos4">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub7} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos5">Contribution de la Blockchain à la sécurisation des transactions financières du réseau des comptables directs : Cas du Trésor Public Togolais  </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>SALAMI MOROU - Ministère de l'Economie et des Finances, Togo</h6>
                      <Link className="read-more mt-10" to="/speaker-infos5">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub6} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos5">Prédiction des hauteurs d’eau journalières du lac Nokoué basé sur un réseau de longue mémoire à court terme.</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>DABIRE Namwinwelbere - Université Abomey Calavi, BURKINA FASO</h6>
                      <Link className="read-more mt-10" to="/speaker-infos5">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub7} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos8">Sécurisation des système biométriques basé sur les empreintes digitales : Dimensionnement d’un modèle basé sur une courbe polynomiale floue. </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>ZANNOU Sourou Viwahoue J. Boris - ED-SI, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos8">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub7} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos9">Equilibre offre demande en 2032 du réseau interconnecté du WAPP. </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>PRODJINOTHO Ulrich Thierry - LETIA UAC, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos9">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub8} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos10">Optimisation de la gestion des ressources humaines éducatives au Bénin grâce à l'Intelligence Artificielle. </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>BELLO Abdou Wahidi - FAST/UAC, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos10">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub7} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos11">Cartographie du bruit routier de la commune d'Abomey-Calavi. </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>Hessou Didier  - École doctorale des sciences de l'ingénieur de l'EPAC, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos11">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mt-50">
            <div className="bisylms-pagination">
              <span className="current">1</span>
              <Link to="/next">2</Link>
              <a href="/next1">3</a>
              <a className="next" href="/next">
                next
                <i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </section>
      </>
    );
}

export default Panels;
