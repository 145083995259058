import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker4() {
    return (
      <>
        <div className="single-post-area mt-40">
          <h5>Étude de la prévalence des hémorroïdes au Bénin</h5>
          <p>
            Les hémorroïdes sont des formations vasculaires complexes normalement présentes chez tout individu [1]. Maladie de civilisation, les hémorroïdes constituent la pathologie la plus fréquente de l’intestin terminal, qui atteint les deux sexes [4]. Les données exactes sur la prévalence des hémorroïdes sont rares [3]. Aujourd’hui, 4 à 86% de la population mondiale, avec un pic entre 45 et 65 ans souffre d’hémorroïdes [4]. Dans chaque famille, il existe au moins une personne souffrante de ce mal et en vieillissant, le risque est bien élevé. De nombreux Américains entre 45 et 65 ans souffrent d'hémorroïdes [5]. En Afrique, la prévalence est estimée en 4005 à 4% de la population selon le mode de vie traditionnelle [6] et au Bénin, les études scientifiques sur les maladies hémorroïdaires restent embryonnaires et éparses. L’hémorroïde est donc un problème médical, de santé publique et socioéconomique important. Afin d’aider la population béninoise à disposer d’antihémorroïdaires moins chères, accessibles et disponibles, des travaux ont été entrepris [7] dont l’étude sur la prévalence des hémorroïdes au Bénin. Un sondage sur la prévalence des hémorroïdes a été mené en ligne via un questionnaire d'enquête par l’outil Google Forme et a couvert de septembre à novembre 4041. Les résultats ont donné des chiffres non négligeables.  60,6 % des enquêtés ont souffert une fois des crises hémorroïdaires. 53,5 % déclarent avoir des hémorroïdes internes, 47,9 % pour les hémorroïdes externes et 18,6 % affirment avoir souffert des deux types. Actuellement, plus de 50 % de Béninois âgés, femmes comme hommes souffrent encore des hémorroïdes et ne savent comment guérir du mal.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />
            <a href="#"></a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker4;
