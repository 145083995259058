import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker12() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Éligibilité à une fonction : contrôle par approches IA et blockchain. </h5>
          <p>
            Le contrôle de l’éligibilité est une étape décisive et éliminatoire pour l’admission soit à une fonction politique ou technique, soit dans un groupe, soit pour un besoin particulier. Ce contrôle se fait selon le cas, sur présentation de pièces (carte d’identité, certificat de nationalité, casier judiciaire, quitus fiscal, etc..). Grâce à l’intelligence artificielle et aux potentialités de la blockchain, ce contrôle pourrait se faire sans qu’aucune pièce physique ne soit nécessaire tout en assurant la protection des données à caractère personnel. Ceci permettra de renforcer la confiance citoyenne et de dématérialiser entièrement le contrôle pour une meilleure célérité. Au début du contrôle, les algorithmes smart-contract et consensus seront mis en œuvre pour requérir l’avis de la personne concernée. Ensuite, diverses sources de données (Police, justice, ANIP, Impôt, etc.) seront sollicitées pour faire la recherche. Le système proposé affichera si la personne est éligible ou pas. Au cas où elle ne serait pas éligible, les informations collectées ayant permis de prendre la décision seront systématiquement rendues accessibles. Une notification de non éligibilité sera faite au concerné par mail avec tous les détails précisant les diverses sources de données.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker12;
