import React from 'react';
import { Link } from 'react-router-dom';
import pub1 from '../../assets/images/blog-6.jpg';
import pub2 from '../../assets/images/publications/comm2.png';
import pub27 from '../../assets/images/publications/comm27.jpg';
import pub7 from '../../assets/images/publications/comm7.jpg';
import pub5 from '../../assets/images/publications/comm5.jpeg';

function Panels2() {
    return (
      <>
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-section-title text-center">
                  <h3 className="appie-title">Nos speakers</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub7} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos20">Sécurisation des système biométriques basé sur les empreintes digitales : Dimensionnement d’un modèle basé sur une courbe polynomiale floue.</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>ZANNOU Sourou Viwahoue J. Boris - ED-SI, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos20">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub5} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos23">Impact of AI-Driven Duolingo on foreign languages learners at CEBELAE</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>SALIFOU ABDOULAYE Salami - CEBELAE/UAC, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos22">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub2} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos23">Contribution de l'intelligence artificielle à la détection précoce du Diabète par l'analyse de l’odeur corporelle.</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>HOUINSOU Bokpè Joanie - UAC, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos23">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub7} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos24">Génotypage du Big Data d’une Source d’Information.</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>ALAKONON Félix Ange - LETIA/EPAC, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos24">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub7} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos25">Analyse des séries temporelles des variables climatiques sur les ressources en eau dans la haute vallée de l'Ouémé à Bétérou à l'aide du modèle saisonnière ARIMA.</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>LAMIDI Taohidi Alamou - UAC, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos25">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub7} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos26">Étude de la mise en place d'un matériau composite à base de sols argileux et de fibres de coque de coco : caractérisation et imagerie par tomographie.</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>AGBELELE Koffi Judicaël  - UNSTIM, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos26">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub27} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos27">
                        Identification des biomarqueurs pharmacogénomiques et développement de modèles d'ia pour une médecine personnalisée dans le traitement du diabète de Type 2 au Bénin.
                      </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>HONZOUNNON Noël Christi - École doctoral des SVT, Bénin</h6>
                      <Link className="read-more mt-10" to="/speaker-infos27">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mt-50">
            <div className="bisylms-pagination">
              <Link to="/JSTIM2023">1</Link>
              <Link to="/next">2</Link>
              <span className="current">3</span>
              <a className="next" href="/JSTIM2023">
                next
                <i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </section>
      </>
    );
}

export default Panels2;
