import React from 'react';
import useToggle from '../../Hooks/useToggle';
import HeaderNews from '../Activities/HeaderNews';
import HeroNews from '../Activities/HeroNews';
import BackToTop from '../BackToTop';
import SignupHomeEight from '../HomeEight/SignupHomeEight';
import TestimonialHomeEight from '../HomeEight/TestimonialHomeEight';
import WorkPartHomeEight from '../HomeEight/WorkPartHomeEight';

import Drawer from '../Mobile/Drawer';

function AboutUsTwo() {
    const [drawer, drawerAction] = useToggle(false);
    return (
      <>
        <Drawer drawer={drawer} action={drawerAction.toggle} />
        <HeaderNews action={drawerAction.toggle} />
        <HeroNews
          title="About Us 2"
          breadcrumb={[
                    { link: '/', title: 'Home' },
                    { link: '/about-us-another', title: 'About us' },
                ]}
        />

        <WorkPartHomeEight className="pt-100" />
        <TestimonialHomeEight />
        <SignupHomeEight />
        <BackToTop />
      </>
    );
}

export default AboutUsTwo;
