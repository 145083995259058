import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker1() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Nonlinear Mixed Models (NLMMs), Appropriate approach for a robust infectious diseases modeling (IDM)</h5>
          <br />
          <br />
          Olaiya Mathilde Adéoti (a), Schadrac Agbla (b), Aliou Diop (c), Romain Glèlè Kakaï (a)
          <p>
            {' '}

            {' '}
            <br />

            a) Laboratoire de Biomathématiques et d’Estimations Forestières, University of Abomey-Calavi, Cotonou, Bénin; mathildeadeoti@yahoo.fr;
            romain.glelekakai@fsa.uac.bj
            {' '}
            <br />
            b) Departement of Health Data Science, University of Liverpool, Liverpool (United Kingdom); schadrac.agbla@gmail.com
            {' '}
            <br />
            c) Laboratoire d’Etude et de Recherche en Statistique et Developpement, Gaston Berger University, Saint-Louis (Senegal); aliou.diop@ugb.edu.sn
            {' '}
            <br />
            <br />
            Abstract
            {' '}
            <br />
            The level of surveillance and preparedness against epidemics vary across countries, resulting in different countries’ responses
            to outbreaks. When interested in estimating the average disease’s behaviour, many used statistical model specifications are not
            flexible enough to accommodate general intra-individual heterogeneity, particularly heteroscedasticity and correlation. Non-
            linear mixed effects models (NLMMs) constitute a particular statistical tool that can overcome such an important challenge.
            While compartmental models are most used in epidemic modeling, NLMMs have the potential to account for individual pat-
            tern variation. This study provides an overview of its current use and offers a solid ground for developing guidelines that may help improve its implementation in real-world situations. Relevant scientific databases in the Research4life Access initiative programs were used to search for papers dealing with key aspects of NLMMs in infectious disease modeling (IDM). From an initial list of 3641 papers, 124 were finally included and used for this systematic and critical review for the last two decades
            following the PRISMA guidelines. NLMMs have evolved rapidly in the last decade, especially in IDM, with most publica-
            tions dating from 2017-2021 (83.33%). The routine use of normality assumption appeared inappropriate for IDM, leading to a wealth of literature on NLMMs with non-normal errors and random effects under various estimation methods. We noticed that NLMMs have attracted much attention for the latest known epidemics worldwide (COVID-19, Ebola, Dengue and Lassa) with
            the robustness and reliability of relaxed propositions of the normality assumption. A case study of the application of COVID-
            19 data helped to highlight NLMMs’ performance in modeling infectious diseases. Out of this study, estimation methods,
            assumptions, and random terms specification in NLMMs are key aspects requiring particular attention for their application in IDM.
            {' '}
            <br />
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#">epidemic</a>
            <a href="#">heterogeneousData</a>
            <a href="#">multilevelNonlinearModels</a>
            <a href="#"> PRISMA </a>
            {' '}
            <br />
            <a href="#"> comparison </a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker1;
