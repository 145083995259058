import React from 'react';
// import of animations;
import { Slide, Zoom } from 'react-awesome-reveal';

function ServicesHomeEight() {
    return (
      <>
        <section
          className="appie-services-2-area appie-services-8-area pt-90 pb-55"
          id="service"
        >
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-6 col-md-8">
                <Zoom>
                  <div className="appie-section-title">
                    <h3 className="appie-title">Secteurs d'orientation</h3>
                    <p>
                      Nous nous investissons dans les secteurs numériques et
                      technologiques afin de promouvoir l'entrepreneuriat numérique au
                      Bénin et en Afrique.
                    </p>
                  </div>
                </Zoom>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <Slide right>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div
                        className="
                                appie-single-service-2 appie-single-service-about
                                mt-30
                                wow
                                animated
                                fadeInUp
                            "
                        data-wow-duration="2000ms"
                        data-wow-delay="200ms"
                      >
                        <div className="icon">
                          <i className="fal fa-graduation-cap" />
                        </div>
                        <h4 className="title">Education</h4>
                        <p>Epanouir la jeunesse Béninoise.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div
                        className="
                                appie-single-service-2 appie-single-service-about
                                item-2
                                mt-30
                                wow
                                animated
                                fadeInUp
                                "
                        data-wow-duration="2000ms"
                        data-wow-delay="400ms"
                      >
                        <div className="icon">
                          <i className="fal fa-tractor" />
                        </div>
                        <h4 className="title">Agriculture</h4>
                        <p>Moderniser l'agriculture.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div
                        className="appie-single-service-2 appie-single-service-about
                                                    item-3
                                                    mt-30
                                                    wow
                                                    animated
                                                    fadeInUp
                                                "
                        data-wow-duration="2000ms"
                        data-wow-delay="600ms"
                      >
                        <div className="icon">
                          <i className="fal fa-notes-medical" />
                        </div>
                        <h4 className="title">Santé</h4>
                        <p>Améliorer le secteur de la santé.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div
                        className="
                                                appie-single-service-2 appie-single-service-about
                                                item-4
                                                mt-30
                                                wow
                                                animated
                                                fadeInUp
                                            "
                        data-wow-duration="2000ms"
                        data-wow-delay="200ms"
                      >
                        <div className="icon">
                          <i className="fal fa-industry" />
                        </div>
                        <h4 className="title">Industrie</h4>
                        <p>Propulser l'industrie béninoise.</p>
                      </div>
                    </div>
                  </div>
                </Slide>
              </div>
              <div className="col-lg-6">
                <Slide left>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div
                        className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                        data-wow-duration="2000ms"
                        data-wow-delay="200ms"
                      >
                        <div className="icon">
                          <i className="fal fa-store" />
                        </div>
                        <h4 className="title">Commerce</h4>
                        <p>
                          Révolutionner le monde de nos importations et
                          exportations.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div
                        className="
                    appie-single-service-2 appie-single-service-about
                    item-2
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                        data-wow-duration="2000ms"
                        data-wow-delay="400ms"
                      >
                        <div className="icon">
                          <i className="fal fa-gavel" />
                        </div>
                        <h4 className="title">Droit</h4>
                        <p>Encourager la numérisation des sciences juridiques.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div
                        className="appie-single-service-2 appie-single-service-about
                                                    item-3
                                                    mt-30
                                                    wow
                                                    animated
                                                    fadeInUp
                                                "
                        data-wow-duration="2000ms"
                        data-wow-delay="600ms"
                      >
                        <div className="icon">
                          <i className="fal fa-coins" />
                        </div>
                        <h4 className="title">Finances</h4>
                        <p>Mener vers les Finances Numériques et Sécurisées.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div
                        className="
                                                appie-single-service-2 appie-single-service-about
                                                item-4
                                                mt-30
                                                wow
                                                animated
                                                fadeInUp
                                            "
                        data-wow-duration="2000ms"
                        data-wow-delay="200ms"
                      >
                        <div className="icon">
                          <i className="fal fa-satellite-dish" />
                        </div>
                        <h4 className="title">Télécoms</h4>
                        <p>
                          Développer des technologies de pointe en communication.
                        </p>
                      </div>
                    </div>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

export default ServicesHomeEight;
