import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker12() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Évaluation de l'efficacité d'un indice de stabilité de tension pour l'identification des nœuds et lignes vulnérables dans un réseau de transport d'énergie électrique. </h5>
          <p>

          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker12;
