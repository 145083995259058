import React from 'react';
import { AttentionSeeker, Fade } from 'react-awesome-reveal';
import workThumb from '../../assets/images/how-it-work-thumb.png';
// import animations

function WorkPartHomeEight() {
    return (
      <>
        <div className="appie-how-it-work-area pt-10 pb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <AttentionSeeker>
                  <div className="how-it-work-thumb text-center">
                    <img src={workThumb} alt="" />
                  </div>
                </AttentionSeeker>
              </div>
              <div className="col-lg-6">
                <Fade>
                  <div className="appie-how-it-work-content">
                    <h2 className="title">Nos programmes</h2>
                    <p>
                      Chaque année, nous organisons des panels de communication, des
                      panel, d'exposition et de pratiques autour d'une thématique
                      scientifique définie :
                    </p>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="how-it-work-box">
                          <span>1</span>
                          <h5 className="title">Discussion</h5>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="how-it-work-box">
                          <span>2</span>
                          <h5 className="title">Exposition</h5>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="how-it-work-box">
                          <span>3</span>
                          <h5 className="title">Ateliers</h5>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="how-it-work-box">
                          <span>4</span>
                          <h5 className="title">Concours entre participants</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default WorkPartHomeEight;
