import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker16() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Analyse numérique du comportement des pieux de fondation d’un pont sur un sol instable.</h5>
          <p>
            La fondation joue un rôle essentiel pour la stabilité et la durabilité des infrastructures tels que les route, ponts, dalots, bâtiments, etc. Mais force est de constater la difficulté de maitrise de cette satbilité et de la durabilité des dites infrastructures réalisées sur des sols instables comme les sols compressibles, les argiles molles. Cette étude examine les performances des pieux forés et battus dans la localité d’Avlékété. A cet effet, des essais in situ et en laboratoire ont été ralisés sur le sol d’étude. Une étude numérique à l’aide d’outils d’éléments finis (GEO5) en vue d’examiner le comportement des pieux d’un ouvrage d’art de type pont. Des résultats obtenus il ressort que le sol étudié est constitué de sable limoneux peu plastique et argile très plastique de type A2 et A4 selon la classification du GTR pour la profondeur d’étude. De l’étude de comportement des deux types de pieux, il ressort une meilleure capacité portante d’ordre de 6578kN pour les pieux forés contre 6261kN pour les pieux battus avec un coefficient de sécurité, de 2.14 et 2.03 respectivement pour les forés et battus.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker16;
