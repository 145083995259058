import React from 'react';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import FooterHomeEight from '../../HomeEight/FooterHomeEight';
import Drawer from '../../Mobile/Drawer';
import Speaker9 from './Speaker9';
import BlogSideBar from '../BlogSideBar';
import HeaderNews from '../HeaderNews';
import HeroNews from '../HeroNews';

function Infos9() {
    const [drawer, drawerAction] = useToggle(false);
    return (
      <>
        <Drawer drawer={drawer} action={drawerAction.toggle} />
        <HeaderNews action={drawerAction.toggle} />
        <HeroNews
          title="Nos speakers"
          breadcrumb={[
                    { link: '/', title: 'Accueil' },
                    { link: '/JSTM2023', title: 'Articles' },
                    { link: '/activités/archives', title: 'Les communications' },
                ]}
        />
        <section className="blogpage-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-7">
                <Speaker9 />
              </div>
              <div className="col-lg-4 col-md-5">
                <BlogSideBar />
              </div>
            </div>
          </div>
        </section>
        <FooterHomeEight />
        <BackToTop />
      </>
    );
}
export default Infos9;
