import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker12() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>EFFET DE LA MASSE THERMIQUE SUR LA COMMATION EN COMBUSTIBLE DES FOYERS DE CUISSON A BIOMASSE SOLIDE  </h5>
          <p>
            L’utilisation des foyers de cuisson de mauvaise qualité a des conséquences néfastes sur la santé des usagers mais aussi sur l’environnement et le couvert forestier. Face à ces problèmes, des études sont de plus en plus conduites afin de développer des foyers de cuisson plus efficaces. Les matériaux utilisés pour la réalisation et/ou la conception des foyers vont de matériaux lourds au matériaux légers. Cependant, les foyers construits à base de matériaux lourds, accumulent dans leurs parois une portion de chaleur produite au lieu que cette dernière atteint la cible de cuisson. L’objectif de cette étude est démontré l’influence de la masse thermique sur la consommation en combustible de foyers. L’étude porte sur un ensemble des foyers disponibles dans la littérature. Ces derniers se diffèrent de part leurs caractéristiques : le combustible utilisé, la présence de cheminé et de jupe, le nombre de marmite. La démarche adaptée consiste d’abord à classer par catégorie l’ensemble de foyers concernés. Ensuite, pour chacune de catégories, seront comparées : la masse du foyer ainsi que la quantité du combustible nécessaire pour porter 5 litres d’eau en ébullition. Il ressort que pour toutes les catégories des foyers étudiés, les foyers lourds consomment plus du combustible que les foyers légers pour une tache de cuisson similaire.
            Mots Clés : Foyer de cuisson, Biomasse solide, Masse thermique, consommation en combustible.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#">FoyerDeCuisson</a>
            <a href="#">BiomasseSolide</a>
            <a href="#">MasseThermique</a>
            <a href="#">consommationEnCombustible</a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker12;
