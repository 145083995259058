import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker24() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>
            Identification des biomarqueurs pharmacogénomiques et développement de modèles d'ia pour une médecine personnalisée dans le traitement du diabète de Type 2 au Bénin.
          </h5>
          <br />
          <p>
            Contexte : Le diabète de type 2 (DT2) est une maladie métabolique complexe et hétérogène avec une incidence croissante, particulièrement en Afrique subsaharienne. La réponse variable des patients aux traitements standard souligne le besoin d'approches personnalisées. Cette étude vise à identifier des biomarqueurs pharmacogénomiques pour développer des modèles d'intelligence artificielle (IA) pour une médecine personnalisée dans le traitement du DT2. Méthodes : Cette recherche implique la collecte de données génétiques, cliniques et environnementales de patients atteints de DT2 au Bénin. Les analyses génomiques et les études environnementales et cliniques sont intégrées pour identifier les facteurs influençant la réponse au traitement. Des modèles d'IA seront développés pour prédire la réponse individuelle aux traitements médicamenteux, basés sur les biomarqueurs identifiés. Résultats Attendus : Il sera identifié des biomarqueurs spécifiques associés à la réponse thérapeutique dans la population béninoise. Les modèles d'IA issus de cette recherche pourraient prédire efficacement la réponse aux traitements, permettant ainsi une personnalisation des soins médicaux. Cela pourrait conduire à une gestion améliorée de la maladie, réduisant ainsi les risques de complications et les coûts de santé associés. Impact : Ce travail pourrait transformer la prise en charge du DT2 en Afrique subsaharienne, en offrant des stratégies thérapeutiques plus ciblées et personnalisées. Il contribue également de manière significative au domaine de la médecine personnalisée, en intégrant la pharmacogénomique et les technologies d'IA pour une prise en charge optimisée des maladies chroniques.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"> DiabèteDeType2</a>
            <a href="#">BiomarqueursPharmacogénomiques</a>
            <a href="#">IA</a>
            <a href="#">GestionThérapeutiqueCiblée</a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker24;
