import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker8() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Sécurisation des système biométriques basé sur les empreintes digitales : Dimensionnement d’un modèle basé sur une courbe polynomiale floue. </h5>
          <p>
            L’authentification par empreintes digitales est l'un des outils largement utilisés pour authentifier les utilisateurs par bon nombre de services numériques. La pratique courante dans les systèmes basés sur les empreintes digitales consiste à stocker des munities d'empreintes digitales en tant que modèle d’utilisateur. Les informations sur les munities peuvent être accessibles via une attaque par un adversaire et l'empreinte digitale originale des utilisateurs peut être obtenue à l'aide de ces informations. Contrairement aux mots de passe, une empreinte digitale compromise est irrévocable. Ainsi, il est nécessaire de protéger les données des utilisateurs et leur permettre de le révoquer en cas d’usurpation. Dans cet article, une technique basée sur une courbe polynomiale est proposée pour sécuriser les informations d'empreintes digitales sans contact extraites. Notre système d’empreinte digitale est un système basé sur les empreintes digitales sans contact obtenues à l’aide d’un dispositif approprié. Ces images obtenues sont traitées (filtrage, binarisation, squelettisation et segmentation, extraction des minuties). Les coordonnées des munities extraites et un jeu de clés utilisateur unique sont sollicités pour générer un modèle sécurisé correspondant à un utilisateur à l'aide d'une transformation non inversible (courbe polynomiale + jeu de clés). Quelques tests ont été fait pour évaluer certaines propriétés des schémas de protection des modèles biométriques. Ce modèle améliore les performances du système d’empreinte digitale sans contact.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />
            <a href="#"></a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker8;
