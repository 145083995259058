import React from 'react';
import singlePost from '../../assets/images/pub3.jpg';

function Blog() {
    return (
      <>
        <div className="single-post-area">
          <p>
            𝘚𝘰𝘶𝘴 𝘭𝘦 𝘩𝘢𝘶𝘵 𝘱𝘢𝘳𝘳𝘢𝘪𝘯𝘢𝘨𝘦   𝘥𝘦 𝙈. 𝘼𝙗𝙙𝙤𝙪𝙡𝙖𝙮𝙚 𝘽𝙄𝙊 𝙏𝘾𝙃𝘼𝙉𝙀, 𝙈𝙞𝙣𝙞𝙨𝙩𝙧𝙚 𝙙'𝙀́𝙩𝙖𝙩 𝙘𝙝𝙖𝙧𝙜𝙚́ 𝙙𝙪 𝘿𝙚́𝙫𝙚𝙡𝙤𝙥𝙥𝙚𝙢𝙚𝙣𝙩 𝙚𝙩 𝙙𝙚 𝙡𝙖 𝘾𝙤𝙤𝙧𝙙𝙞𝙣𝙖𝙩𝙞𝙤𝙣 𝙙𝙚 𝙡'𝙖𝙘𝙩𝙞𝙤𝙣 𝙜𝙤𝙪𝙫𝙚𝙧𝙣𝙚𝙢𝙚𝙣𝙩𝙖𝙡𝙚 𝘦𝘵 𝘚𝘰𝘶𝘴 𝘭𝘦 𝘭𝘦𝘢𝘥𝘦𝘳𝘴𝘩𝘪𝘱 𝘥𝘦 𝙈. 𝙏𝙖𝙝𝙞𝙧𝙤𝙪 𝘿𝙅𝘼𝙍𝘼, 𝘔𝘢𝘪̂𝘵𝘳𝘦 𝘥𝘦 𝘊𝘰𝘯𝘧𝘦́𝘳𝘦𝘯𝘤𝘦𝘴 𝘥𝘦𝘴 𝘜𝘯𝘪𝘷𝘦𝘳𝘴𝘪𝘵𝘦́𝘴 𝘥𝘶 𝘊𝘈𝘔𝘌𝘚, 𝘭'𝘐𝘯𝘴𝘵𝘪𝘵𝘶𝘵 𝘥'𝘐𝘯𝘯𝘰𝘷𝘢𝘵𝘪𝘰𝘯 𝘛𝘦𝘤𝘩𝘯𝘰𝘭𝘰𝘨𝘪𝘲𝘶𝘦 (𝘐𝘐𝘛𝘌𝘊𝘏) 𝘦𝘯 𝘱𝘢𝘳𝘵𝘦𝘯𝘢𝘳𝘪𝘢𝘵 𝘢𝘷𝘦𝘤 𝘭’𝘌𝘤𝘰𝘭𝘦 𝘋𝘰𝘤𝘵𝘰𝘳𝘢𝘭𝘦 𝘋𝘦𝘴 𝘚𝘤𝘪𝘦𝘯𝘤𝘦𝘴 𝘥𝘦 𝘭’𝘐𝘯𝘨𝘦́𝘯𝘪𝘦𝘶𝘳 (𝘌𝘋-𝘚𝘋𝘐) 𝘥𝘪𝘳𝘪𝘨𝘦́𝘦 𝘱𝘢𝘳 le Professeur Titulaire 𝗠𝗼𝗵𝗮𝗺𝗲𝗱 𝗚𝗜𝗕𝗜𝗚𝗔𝗬𝗘, 𝘰𝘳𝘨𝘢𝘯𝘪𝘴𝘦𝘯𝘵 𝘭𝘢 𝘵𝘳𝘰𝘪𝘴𝘪𝘦̀𝘮𝘦 𝘦́𝘥𝘪𝘵𝘪𝘰𝘯 𝘥𝘦𝘴 𝘑𝘰𝘶𝘳𝘯𝘦́𝘦𝘴 𝘥𝘦𝘴 𝘚𝘤𝘪𝘦𝘯𝘤𝘦𝘴 𝘛𝘦𝘤𝘩𝘯𝘰𝘭𝘰𝘨𝘪𝘦𝘴, 𝘐𝘯𝘨𝘦́𝘯𝘪𝘦𝘳𝘪𝘦 𝘦𝘵 𝘔𝘢𝘵𝘩𝘦́𝘮𝘢𝘵𝘪𝘲𝘶𝘦𝘴 (𝘑𝘚𝘛𝘐𝘔).
          </p>
          <div className="post-thumb">
            <img src={singlePost} alt="" />
          </div>
          <h4 className="article-title">Colloque scientifique à l'UAC</h4>
          <p>
            <span role="img" aria-label="">🕑</span>
            {' '}
            𝑫𝒂𝒕𝒆 : 13 𝒂𝒖 20 𝒅é𝒄𝒆𝒎𝒃𝒓𝒆 2023
            {' '}
            <br />
            <span role="img" aria-label="">🚴‍♂</span>
            𝑳𝒊𝒆𝒖 : 𝑳'𝑼𝒏𝒊𝒗𝒆𝒓𝒔𝒊𝒕é 𝒅'𝑨𝒃𝒐𝒎𝒆𝒚 𝑪𝒂𝒍𝒂𝒗𝒊 & 𝑩𝒂𝒏𝒊𝒌𝒐𝒂𝒓𝒂
            <br />
            <span role="img" aria-label=""> 🔗</span>
            {' '}
            𝘑𝘰𝘪𝘨𝘯𝘦𝘻-𝘷𝘰𝘶𝘴 à 𝘯𝘰𝘶𝘴 𝘱𝘰𝘶𝘳 𝘶𝘯𝘦 𝘴𝘦𝘮𝘢𝘪𝘯𝘦 𝘥'𝘦𝘹𝘱𝘭𝘰𝘳𝘢𝘵𝘪𝘰𝘯, 𝘥𝘦 𝘱𝘢𝘳𝘵𝘢𝘨𝘦 𝘥𝘦 𝘤𝘰𝘯𝘯𝘢𝘪𝘴𝘴𝘢𝘯𝘤𝘦𝘴 𝘦𝘵 𝘥'𝘪𝘯𝘯𝘰𝘷𝘢𝘵𝘪𝘰𝘯.
            𝘌𝘯𝘴𝘦𝘮𝘣𝘭𝘦, 𝘯𝘰𝘶𝘴 𝘤𝘳é𝘰𝘯𝘴 𝘭'𝘢𝘷𝘦𝘯𝘪𝘳 𝘥𝘦 𝘭'é𝘥𝘶𝘤𝘢𝘵𝘪𝘰𝘯 𝘵𝘦𝘤𝘩𝘯𝘰𝘭𝘰𝘨𝘪𝘲𝘶𝘦.
            {' '}
            <br />
          </p>
          <blockquote>
            <p>
              L'éducation est l'arme la plus puissante que vous puissiez utiliser pour changer le monde.
            </p>
            <cite>Inédit</cite>
          </blockquote>

          <div className="post-tags">
            <h5>Tags:</h5>
            <a href="#">𝑱𝑺𝑻𝑰𝑴2023</a>
            <a href="#"> É𝒅𝒖𝒄𝒂𝒕𝒊𝒐𝒏𝑻𝒆𝒄𝒉𝒏𝒐𝒍𝒐𝒈𝒊𝒒𝒖𝒆</a>
            <a href="#">𝑰𝒏𝒏𝒐𝒗𝒂𝒕𝒊𝒐𝒏 </a>
            <a href="#"> 𝑰𝑨𝒆𝒕É𝒅𝒖𝒄𝒂𝒕𝒊𝒐𝒏 </a>
            {' '}
            <br />
            <a href="#"> 𝑺𝒐𝒚𝒆𝒛𝑫𝒆𝒔𝑵ô𝒕𝒓𝒆𝒔 </a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Blog;
