import React, { useState } from 'react';
import { JackInTheBox } from 'react-awesome-reveal';
import CounterUpCom from '../../lib/CounterUpCom';
import PopupVideo from '../PopupVideo';
// import animations

function FaqHomeEight() {
    const [showQuestion, setQuestion] = useState(0);
    const [showVideo, setVideoValue] = useState(false);
    const openQuestion = (e, value) => {
        e.preventDefault();
        setQuestion(value);
    };
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    return (
      <>
        {showVideo && (
        <PopupVideo
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
          handler={(e) => handleShowVideo(e)}
        />
            )}
        <div className="appie-faq-8-area pt-100 pb-100" id="counter">
          <div className="container">
            <div className="row">
              <JackInTheBox>
                <div className="col-lg-5">
                  <div className="appie-section-title">
                    <h3 className="appie-title">FAQs</h3>
                    <p>Ce que nos internautes demandent le plus souvent.</p>
                  </div>
                  <div
                    className="faq-accordion wow fadeInRight mt-30"
                    data-wow-duration="1500ms"
                  >
                    <div
                      className="accrodion-grp wow fadeIn faq-accrodion"
                      data-wow-duration="1500ms"
                      data-grp-name="faq-accrodion"
                    >
                      <div
                        className={`accrodion ${
                                            showQuestion === 0 ? 'active' : ''
                                        }`}
                        onClick={(e) => openQuestion(e, 0)}
                      >
                        <div className="accrodion-inner">
                          <div className="accrodion-title">
                            <h4>Qu'est-ce que la JSTIM ?</h4>
                          </div>
                          <div
                            className="accrodion-content"
                            style={{
                                                    display: showQuestion === 0 ? 'block' : 'none',
                                                }}
                          >
                            <div className="inner">
                              <p>
                                Il s'agit des journées de sciences et
                                technologies organisées afin d'orienter les
                                jeunes béninois dans les secteurs clés de
                                développement en les armant selon le
                                l'évolution technologique dans le monde.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`accrodion ${
                                            showQuestion === 1 ? 'active' : ''
                                        }`}
                        onClick={(e) => openQuestion(e, 1)}
                      >
                        <div className="accrodion-inner">
                          <div className="accrodion-title">
                            <h4>Comment y participer?</h4>
                          </div>
                          <div
                            className="accrodion-content"
                            style={{
                                                    display: showQuestion === 1 ? 'block' : 'none',
                                                }}
                          >
                            <div className="inner">
                              <p>
                                Vous êtes un(e) passionné(e) de nouvelles
                                technologies? Cliquez sur l'onglet JSTIM.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`accrodion ${
                                            showQuestion === 2 ? 'active' : ''
                                        }`}
                        onClick={(e) => openQuestion(e, 2)}
                      >
                        <div className="accrodion-inner">
                          <div className="accrodion-title">
                            <h4>
                              Que puis-je faire
                              à la JSTIM?
                            </h4>
                          </div>
                          <div
                            className="accrodion-content"
                            style={{
                                                    display: showQuestion === 2 ? 'block' : 'none',
                                                }}
                          >
                            <div className="inner">
                              <p>
                                Vous êtes les bienvenu(e)s à la JSTIM si
                                vous voulez faire des communications sur la
                                thématique définie, si vous voulez exposer
                                des projets que vous avez, si vous voulez
                                vous mettre au pas pour cette révolution
                                numérique.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </JackInTheBox>
            </div>
          </div>
          <div className="faq-play-box">
            <div className="play-btn">
              <a
                onClick={(e) => handleShowVideo(e)}
                className="appie-video-popup"
                href="https://www.youtube.com/watch?v=EE7NqzhMDms"
              >
                <i className="fas fa-play" />
              </a>
            </div>
            <div className="faq-play-counter">
              <div className="box-1">
                <h4 className="title">
                  <CounterUpCom endValue="100" sectionSelect="counter" />
                </h4>
                <span>Panelistes</span>
              </div>
              <div className="box-1 box-2">
                <h4 className="title">
                  <CounterUpCom endValue="1000" sectionSelect="counter" />
                </h4>
                <span>Participants</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default FaqHomeEight;
