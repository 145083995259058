import React from 'react';
// import of animations;
import {
  Bounce,
  Rotate,
  Slide, Zoom,
} from 'react-awesome-reveal';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import team1 from '../../assets/images/AKD.png';
import team3 from '../../assets/images/AKDP.png';
import teamFive from '../../assets/images/AS.png';
import team6 from '../../assets/images/CA.jpeg';
import teamEight from '../../assets/images/EC.png';
import team5 from '../../assets/images/GK.png';
import teamNine from '../../assets/images/JS.jpg';
import team2 from '../../assets/images/MK.png';
import teamSix from '../../assets/images/MO.png';
import teamTen from '../../assets/images/NBN.png';
import teamSeven from '../../assets/images/PS.png';
import team4 from '../../assets/images/RA.jpeg';
import teamOne from '../../assets/images/TDJA.jpg';
import teamTwo from '../../assets/images/VD.png';
import teamThree from '../../assets/images/VDO.jpeg';

function TeamHomeEight() {
    return (
      <>
        <section
          className="
        appie-team-area appie-team-about-area appie-team-8-area
        pt-90
        pb-90
      "
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-section-title text-center">
                  <h2 className="appie-title">Notre équipe</h2>
                  <h5 className="subtitle mt-5">Comité d'organisation</h5>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div
                  className="testimonial-about-slider-active"
                  style={{ position: 'relative' }}
                >
                  <Slide>
                    <div className="row">
                      <div className=" col-lg-3 col-md-3">
                        <div
                          className="appie-team-item appie-team-item-about appie-team-item-8
                                                      mt-30
                                                      wow
                                                      animated
                                                      fadeInUp
                                                  "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamOne}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Membre de la Commission Permanente SF2I
                                / ANSALB.
                                <br />
                                {' '}
                                Chef du département Génie
                                Informatique et
                                {' '}
                                <br />
                                Télécommunications de l’EPAC.
                                Coordonnateur du Master
                                Télécommunications et Réseau
                                Informatique de l’Ecole Doctorale des
                                Sciences de l’Ingénieur.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Prof Tahirou DJARA</h5>
                            <h6>Président</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <div
                          className="
                                                      appie-team-item appie-team-item-about appie-team-item-8
                                                      mt-30
                                                      wow
                                                      animated
                                                      fadeInUp
                                                  "
                          data-wow-duration="2000ms"
                          data-wow-delay="400ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamTwo}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Ecole Doctorale des Sciences de
                                l’Ingénieur, UAC | 2019 Coordonnateurs
                                des cours doctoraux Coordonnateur
                                adjoint Master en Matériaux et
                                Structures.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Prof Valéry DOKO </h5>
                            <h6>Vice-Président</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <div
                          className="
                                                      appie-team-item appie-team-item-about appie-team-item-8
                                                      mt-30
                                                      wow
                                                      animated
                                                      fadeInUp
                                                  "
                          data-wow-duration="2000ms"
                          data-wow-delay="600ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamThree}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Enseignant Chercheur à l'EPAC, Maître de
                                Conférences des Universités du CAMES.
                                Département de Génie de Biologie Humaine
                                (GBH), École Polytechnique
                                d'Abomey-Calavi (EPAC),
                                <br />
                                {' '}
                                Université d'Abomey-Calavi (UAC).
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">
                              Prof Victorien DOUGNON
                            </h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-3 col-md-3">
                        <div
                          className="
                                                      appie-team-item appie-team-item-about appie-team-item-8
                                                      mt-30
                                                      wow
                                                      animated
                                                      fadeInUp
                                                  "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamFive}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Directeur des Systèmes d’Information
                                (DSI) au Ministère du Cadre de Vie et
                                des Transports, chargé du Développement
                                Durable.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Dr Abdou Aziz SOBABE</h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slide>
                  <Zoom>
                    <div className="row">

                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                      appie-team-item appie-team-item-about appie-team-item-8
                                                      mt-30
                                                      wow
                                                      animated
                                                      fadeInUp
                                                  "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamSix}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Auditeur, Responsable Informatique de la
                                Cour suprême.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Dr Matine OUSMANE</h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                      appie-team-item appie-team-item-about appie-team-item-8
                                                      mt-30
                                                      wow
                                                      animated
                                                      fadeInUp
                                                  "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamSeven}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Enseignant-Chercheur à l’UNSTIM Maître
                                Assistant des Universités du CAMES
                                Directeur des Projets et du
                                Développement Informatique du Groupe
                                CASE&CO.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Dr Patrick SOTINDJO</h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                      appie-team-item appie-team-item-about appie-team-item-8
                                                      mt-30
                                                      wow
                                                      animated
                                                      fadeInUp
                                                  "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamEight}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Enseignant-Chercheur à l'École de Génie
                                Rural (EGR) de l'UNA.
                                {' '}
                                <br />
                                {' '}
                                Maître
                                Assistant des Universités du CAMES.
                                Coordonnateur des Masters de l'EGR.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Dr Edem CHABI</h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                      appie-team-item appie-team-item-about appie-team-item-8
                                                      mt-30
                                                      wow
                                                      animated
                                                      fadeInUp
                                                  "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamNine}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Ingénieur en réseaux informatiques et
                                Télécoms, Chef Projet à IITECH.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Ing Jéhovah-nis SONON</h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                      appie-team-item appie-team-item-about appie-team-item-8
                                                      mt-30
                                                      wow
                                                      animated
                                                      fadeInUp
                                                  "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamTen}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Ingénieur en réseaux informatiques et
                                Internet, Développeur Web à IITECH.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Ing Newton BOCO</h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Rotate>
                  <div className="appie-section-title text-center mt-5">
                    <h5>Comité scientifique</h5>
                  </div>
                </Rotate>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <Bounce>
                  <div
                    className="testimonial-about-slider-active"
                    style={{ position: 'relative' }}
                  >
                    <div className="row">
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                        appie-team-item appie-team-item-about appie-team-item-8
                                                        mt-30
                                                        wow
                                                        animated
                                                        fadeInUp
                                                    "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamOne}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Membre de la Commission Permanente SF2I
                                / ANSALB.
                                <br />
                                {' '}
                                Chef du département Génie
                                Informatique et
                                {' '}
                                <br />
                                Télécommunications de l’EPAC.
                                Coordonnateur du Master
                                Télécommunications et Réseau
                                Informatique de l’Ecole Doctorale des
                                Sciences de l’Ingénieur.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Prof Tahirou DJARA</h5>
                            <h6>Président</h6>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                appie-team-item appie-team-item-about appie-team-item-8
                                                mt-30
                                                wow
                                                animated
                                                fadeInUp
                                            "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img src={team1} alt="" className="teamcard1" />
                            <ul>
                              <span className="infos">
                                Professeur Titulaire des Universités
                                {' '}
                                <br />
                                Membre de la Commission Permanente SF2I
                                ; Président Sous-Comité TIC-ANSALB
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Pr Ahmed D. KORA</h5>
                            <h6>Vice-Président</h6>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                            appie-team-item appie-team-item-about appie-team-item-8
                                                            mt-30
                                                            wow
                                                            animated
                                                            fadeInUp
                                                        "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img src={team2} alt="" className="teamcard" />
                            <ul>
                              <span className="infos">
                                Enseignant à l'UAC.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Pr Marc Kokou ASSOGBA</h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                            appie-team-item appie-team-item-about appie-team-item-8
                                                            mt-30
                                                            wow
                                                            animated
                                                            fadeInUp
                                                        "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img src={team3} alt="" className="teamcard2" />
                            <ul>
                              <span className="infos">
                                Sénégal, Université Alioune DIOP de
                                Bambey.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">
                              Prof Abdou Khadre DIOP
                            </h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                            appie-team-item appie-team-item-about appie-team-item-8
                                                            mt-30
                                                            wow
                                                            animated
                                                            fadeInUp
                                                        "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img src={team4} alt="" className="teamcard" />
                            <ul>
                              <span className="infos">
                                Enseignant - Chercheur à l’UNSTIM.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Pr Richard AGBOKPANZO</h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                    appie-team-item appie-team-item-about appie-team-item-8
                                                    mt-30
                                                    wow
                                                    animated
                                                    fadeInUp
                                                "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamSeven}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Enseignant-Chercheur à l’UNSTIM Maître
                                Assistant des Universités du CAMES
                                Directeur des Projets et du
                                Développement Informatique du Groupe
                                CASE&CO.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Dr Patrick SOTINDJO</h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                            appie-team-item appie-team-item-about appie-team-item-8
                                                            mt-30
                                                            wow
                                                            animated
                                                            fadeInUp
                                                        "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img src={team5} alt="" className="teamcard" />
                            <ul>
                              <span className="infos">
                                Enseignant-Chercheur, Chef service
                                informatique de l’UNSTIM.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">
                              Dr Géraud AZEHOUN-PAZOU
                            </h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                            appie-team-item appie-team-item-about appie-team-item-8
                                                            mt-30
                                                            wow
                                                            animated
                                                            fadeInUp
                                                        "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamFive}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Directeur des Systèmes d’Information
                                (DSI) au Ministère du Cadre de Vie et
                                des Transports, chargé du Développement
                                Durable.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Dr Abdou Aziz SOBABE</h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                            appie-team-item appie-team-item-about appie-team-item-8
                                                            mt-30
                                                            wow
                                                            animated
                                                            fadeInUp
                                                        "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img
                              src={teamSix}
                              alt=""
                              className="teamcard"
                            />
                            <ul>
                              <span className="infos">
                                Auditeur, Responsable Informatique de la
                                Cour suprême.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">Dr Matine OUSMANE</h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-3 col-md-6">
                        <div
                          className="
                                                            appie-team-item appie-team-item-about appie-team-item-8
                                                            mt-30
                                                            wow
                                                            animated
                                                            fadeInUp
                                                        "
                          data-wow-duration="2000ms"
                          data-wow-delay="200ms"
                        >
                          <div className="thumb">
                            <img src={team6} alt="" className="teamcard" />
                            <ul>
                              <span className="infos">
                                Maître de Conférences des Universités.
                                {' '}
                                <br />
                                {' '}
                                Généticien et Biologiste
                                Moléculaire Responsable Division Examen
                                de l’Ecole Polytechnique
                                d’Abomey-Calavi.
                              </span>
                            </ul>
                          </div>
                          <div className="content text-left">
                            <h5 className="title">
                              Pr Camus Mahougnon ADOLIGBE
                            </h5>
                            <h6>Membre</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Bounce>
              </div>
            </div>
          </div>
          {' '}

        </section>
      </>
    );
}

export default TeamHomeEight;
