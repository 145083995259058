import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker17() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Intégration de la Trust over IP dans la Sécurisation des Communications VoIP : Perspectives et Défis</h5>
          <p>
            La Téléphonie sur IP (VoIP) est devenue un pilier essentiel des communications d'entreprise et personnelles malgré son exposition à diverses menaces de sécurité. La Trust over IP (ToIP) émerge comme un moyen prometteur d'améliorer la sécurité des communications VoIP en introduisant des concepts de confiance et d'authenticité dans l'écosystème à travers la blockchain (avec ses caractéristiques comme le consensus, la provenance, l’immuabilité, et la finalité), les registres distribués ou encore les systèmes de fichiers décentralisés comme technologie sous-jacente. Ce papier explore les perspectives et les défis de l'intégration de la Trust over IP (ToIP) dans la sécurisation des communications VoIP, mettant en lumière son potentiel tout en abordant les obstacles à surmonter.
            <br />
            Telephony over IP (VoIP) has become an essential element of corporate and personal communications, despite its exposure to various security threats. Trust over IP (ToIP) is emerging as a promising way to improve the security of VoIP communications by introducing concepts of trust and authenticity into the ecosystem through blockchain (with its characteristics such as consensus, provenance, immutability, and finality), distributed registries or decentralized file systems as the underlying technology. This paper explains the prospects and challenges of integrating Trust over IP (ToIP) into secure VoIP communications, highlighting its potential while addressing the obstacles to be overcome.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker17;
