import React from 'react';
import { useHistory } from 'react-router-dom';
//

function Error() {
    const history = useHistory();
    const goBack = (e) => {
        e.preventDefault();
        history.goBack();
    };

    return (
      <>
        <div className="appie-error-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="appie-error-content text-center">

                  <span>Félicitations!</span>
                  <h3 className="title">Bienvenue à la JSTIM.</h3>
                  <p>
                    C'est un plaisir pour nous de vous voir chez nous.
                  </p>
                  <a onClick={(e) => goBack(e)} Link to="/">
                    Retour
                    {' '}
                    <i className="fal fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Error;
