import React from 'react';
import { Link } from 'react-router-dom';
import pub10 from '../../assets/images/publications/comm10.jpeg';
import pub11 from '../../assets/images/publications/comm11.jpeg';
import pub12 from '../../assets/images/publications/comm12.jpg';
import pub2 from '../../assets/images/publications/comm2.png';
import pub7 from '../../assets/images/publications/comm7.jpg';
import pub9 from '../../assets/images/publications/comm9.jpeg';
import pub13 from '../../assets/images/publications/comm13.jpg';

function Panels1() {
    return (
      <>
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-section-title text-center">
                  <h3 className="appie-title">Nos speakers</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub2} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos12">EFFET DE LA MASSE THERMIQUE SUR LA COMMATION EN COMBUSTIBLE DES FOYERS DE CUISSON A BIOMASSE SOLIDE.</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>GARBA IRRO Maman Nazifi - Ecole doctorale des sciences de l’ingénieur, Université D’Abomey Calavi/UAC, Niger</h6>
                      <Link className="read-more mt-10" to="/speaker-infos12">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub9} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos13">Éligibilité à une fonction : contrôle par approches IA et blockchain. </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>BEKPON François - Ministère de l'Economie et des Finances, Bénin.</h6>
                      <Link className="read-more mt-10" to="/speaker-infos13">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub7} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos15">Conception d’une ligne industrielle de production de pouzzolane de déchets agricoles et de traitement thermique de matières argileuses. </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>GANMAVO Boris - Ecole Doctorale des Science de l'Ingénieur/UAC, Bénin.</h6>
                      <Link className="read-more mt-10" to="/speaker-infos15">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub2} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos16">Analyse numérique du comportement des pieux de fondation d’un pont sur un sol instable. </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>SOGLO Oriane - ENSTP, Bénin.</h6>
                      <Link className="read-more mt-10" to="/speaker-infos16">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub10} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos17">Intégration de la Trust over IP dans la Sécurisation des Communications VoIP : Perspectives et Défis. </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>SONON Sekoude Jehovah-nis Pedrie (premier auteur), Tahirou DJARA (second auteur) - EDSDI UAC / IITECH, Bénin.</h6>
                      <Link className="read-more mt-10" to="/speaker-infos17">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub11} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos18">A Comparative study of Deep Reinforcement learning methods for Cryptocurrency Trading. </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>Fangnon Dieu-Donné - African Institute for Mathematical Sciences (AIMS), Senegal.</h6>
                      <Link className="read-more mt-10" to="/speaker-infos18">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub12} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos19">Impact de l'intégration d’une plateforme d'e-learning personnalisée pour les besoins spécifiques des universités sur l'expérience d'apprentissage des étudiants : cas de GestStudent e-learning. </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>Tahirou DJARA, (premier auteur), Jehovah-nis SONON  (second auteur), Newton BOCO  (troisième auteur) - EDSDI UAC / IITECH, Bénin.</h6>
                      <Link className="read-more mt-10" to="/speaker-infos19">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub7} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos20">DETECTION INTELLIGENTE DE PATHOLOGIES CARDIAQUES PAR LE PHONOCARDIOGRAMME</Link>
                    </h5>
                    <div className="meta-item">
                      <h6>DOTONOU Cosme - EDSDI UAC, Bénin.</h6>
                      <Link className="read-more mt-10" to="/speaker-infos20">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub13} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos21">
                        Comment transformer son projet de soutenance en un produit international sans financement.
                      </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>SALIFOU Zoumarou Blaise - SAB INNOVATION, Bénin. </h6>
                      <Link className="read-more mt-10" to="/speaker-infos21">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                  <div className="thumb">
                    <img src={pub7} alt="" className="pubcard1" />
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <Link to="/speaker-infos22">Stratégie d'intégration de l'intelligence artificielle (IA) dans les systèmes éducatifs en Afrique. </Link>
                    </h5>
                    <div className="meta-item">
                      <h6>Lokoundjou Yanouba Dingamadji - Université de Ngaoundéré, Tchad.</h6>
                      <Link className="read-more mt-10" to="/speaker-infos22">
                        Lire plus
                        <i className="fal fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="col-lg-12 mt-50">
            <div className="bisylms-pagination">
              <Link to="/JSTIM2023">1</Link>
              <span className="current">2</span>
              <Link to="/next1">3</Link>
              <a className="next" href="/next1">
                next
                <i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </section>
      </>
    );
}

export default Panels1;
