import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker6() {
    return (
      <>
        <div className="single-post-area mt-50">
          <h5>On graceful and cordial graph labelings</h5>
          <p>
            Graph labeling is a fundamental area of study in graph theory, involving the assignment of non-negative integers to the vertices or edges of a graph according to some conditions. Graph theory often involves finding vertex or edge labelings that satisfy specific properties, as these provide valuable information about the graph's structure. Two important types of labeling are called Graceful labeling and Cordial labeling. Graceful labeling, introduced by Rosa in 1967, focuses on assigning labels to vertices to obtain distinct edge labels based on the end vertices labels. On the other hand, cordial labeling, proposed by Cahit in 1987, considers the balance between the number of vertices and edges labeled 0 and 1. Vertex or edge labeling enables us to study and analyze various aspects of a graph, leading to several interesting graph theory results. A proper vertex coloring, for example, is one in which adjacent vertices have different colors. Proper vertices coloring allows you to understand the relationship between them and identify patterns or constraints in their connectivity. Vertex coloring has many practical applications, such as in wireless networks, where coloring is the foundation of so-called TDMA MAC protocols. In general, vertex coloring is used to break symmetries, which is a major theme in distributed computing. <br />
            Charmaine A. O. A. Yebadokpo, Audace A. V. DOSSOU-OLORY (Institut National de l'Eau, Université d'Abomey-Calavi) and Rémi AVOHOU ( ICMPA, Université d'Abomey-Calavi) work aims to review the main results of both graceful and cordial labelings, provide implementations of relevant algorithms in the Python programming language, and examine computational approaches to further understand these labelings. Methodologically, after a review of graceful and cordial labelings, we implemented some relevant algorithms using Python, discussed the testing and validation of the implemented algorithms, and presented the results obtained from applying the algorithms to different graphs. Generally as result, we notice that in some cases, labels are assigned to all vertices correctly, and, in some cases, not correctly because of the types of graph giving to the algorithms. By evaluating the execution time of applying each algorithms to different graph, we notice variation of this time, indicating that the algorithm’s performance depends on the size and complexity of the input graph. The implementation and testing of these algorithms provide insights into their computational performance and effectiveness.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />
            <a href="#"></a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker6;
