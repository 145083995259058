import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker5() {
    return (
      <>
        <div className="single-post-area mt-50">
          <h5>Prédiction des hauteurs d’eau journalières du lac Nokoué basé sur un réseau de longue mémoire à court terme.</h5>
          <br />
          <p>
            La prévision des flux hydrologiques (pluie-hauteur ou pluie-débit) revêt une importance croissante dans la gestion des risques hydrologiques tels que les inondations. Dans cette étude, les méthodes de réseau neuronal artificiel récurrent notamment la cellule de longue mémoire à court terme (LSTM, Long Short-Term Memory network), un algorithme de pointe dédié aux séries temporelles, est appliquée pour prédire le niveau d’eau journalier du lac Nokoué au Bénin. Cet article vise à fournir une méthode efficace et fiable capable de reproduire le niveau d’eau journalier futur du lac Nokoué qui est dominé par une combinaison de deux phénomènes : phénomène pluvial (les précipitations) et le phénomène fluvial (les eaux de ruissellement du fleuve Ouémé, de la rivière Sô, de la lagune de Porto-Novo et ceux de l’océan Atlantic). L’analyse des performances suivant l’horizon de prévision indique que le LSTM peut prédire le niveau d’eau du lac Nokoué jusqu’à un horizon de prévision de date t+10 jours. Les métriques de performance telles que la racine carrée de l’erreur quadratique moyenne (RMSE), le coefficient de corrélation (R²), l’efficacité de Nash-Sutcliffe (NSE) et l’erreur absolue moyenne (MAE) s’accordent sur un horizon de prévision allant jusqu’à une date t+3 jours. La variation des valeurs de ces métriques sont stables pour les horizons de t+1 jours, t+2 jours et t+3 jours. La valeur du R², NSE sont supérieures à 0,97 pendant les phases d’entraînement et d’essai dans le bassin du lac Nokoué. A travers l’ensemble des indices d’évaluation utilisés pour discrétiser le modèle mis en place sur l’horizon de prévision adéquat du niveau d’eau dans le bassin du lac Nokoué, l’horizon de prévision de t+3 jours est retenu pour la prévision des hauteurs d’eau journalier futurs.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />
            <a href="#"></a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker5;
