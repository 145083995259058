import React from 'react';

function HeroService() {
    return (
      <>
        <div className="appie-page-title-area appie-page-service-title-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-page-title-item">
                  <span>A vos calendriers ! ! !</span>
                  <h3 className="title">
                    Le programme
                    {' '}
                    <br />
                    {' '}
                    de la #JSTIM 2023.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default HeroService;
