import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker10() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Cartographie du bruit routier de la commune d'Abomey-Calavi </h5>
          <p>
            Auteurs : Didier HESSOU (a), Aristide C. HOUNGAN (a,b), Armand A. DJOSSOU (a,b), Sylvain M. DJIKOU (a), Mathieu ADJASSIN (a), Malahimi ANJORIN (a)
            (a)Laboratoire de caractérisation thermophysique des matériaux et d’appropriation énergétique (Labo-CTMAE) de l’école polytechnique d’Abomey-Calavi (EPAC), 01 BP 2009, Cotonou(Benin).
            (b)Laboratoire de Recherche Pluridisciplinaire de l’Enseignement Technique LARPET/ENSET/Lokossa/UNSTIM, Abomey BP 133 Lokossa.
            <br />
            Résumé
            <br />
            L'objectif principal de nos travaux de recherches est d’évaluer le niveau d’exposition de la population de la commune d’Abomey-Calavi au bruit routier. Pour y parvenir, des mesures des coordonnées géographiques des sites ciblés et des niveaux des intensités sonores émises en ces lieux, ont été effectuées à l’aide respectivement de l’application « mobile topographer » installée sur un portable androïd et du sonomètre. A cet effet, quarante (40) points de mesures dont douze (12) centres de santé, dix (10) établissements d’enseignement et dix-huit (18) carrefours ont été ciblés. Grâce au logiciel ArcGIS 10.1 et à la méthode d’interpolation IDW, les cartes de bruit, basées sur les indicateurs acoustiques Ln et Lden, ont été élaborées. Les résultats de cette étude montrent que les niveaux sonores considérés varient selon le lieu et la période de la journée, à l’exception de la partie Nord du domaine d’étude qui demeure la zone la plus calme quelles que soient les considérations faites. Seulement cinq (05) des sites considérés sont dans une situation acceptable. Le CEG Godomey, quant à lui, est dans une situation critique.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#">CarteDeBruit</a>
            <a href="#">NiveauSonore</a>
            <a href="#">Ln</a>
            <a href="#">Lden</a>
            {' '}
            <br />
            <a href="#">IndicateursAcoustiques</a>
            <a href="#">ZoneCalme</a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker10;
