import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker3() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Etude de l’influence du taux de compactage sur la conductivité thermique d’un matériau routier granulaire non lié : cas de la grave latéritique. </h5>
          <p>
            La présente communication a pour objectif principal l’étude de l’influence du taux de compactage sur la conductivité thermique d’un matériau routier granulaire non lié. Pour y parvenir, nous avons, dans un premier temps parlé d’une généralité incluant la chaussée, les matériaux de construction routière plus précisément la grave latéritique et la métrologie thermique. Après les essais classiques d’identification physiques ayant montré que le matériau a de bonnes caractéristiques pour être utilisé en couche d’assise, nous avons entrepris d’étudier sa conductivité thermique à différent taux de compactage (95 ; 96 ; 97 ; 97,9 ; 99 et 100%). Afin de calculer les valeurs de conductivité thermique, nous nous sommes basés sur l’équation de la chaleur en considérant les températures fournies par le thermocouple accolé à la sonde, au bout d’un temps minimum de 1000s. Nous avons utilisé des moules en PVC respectant les caractéristiques des moules de Proctor modifié afin de pouvoir contenir la propagation du flux de chaleur dans le matériau. Pour nos travaux, la conductivité thermique a été mesurée sur le matériau compacté à différents taux afin de pouvoir déterminer l’influence qu’a le taux de compacité sur les mesures de ce paramètre. Les résultats en moyenne sont donc :  = 0,13396947 W/m.°C ;  = 0,17669852 W/m.°C ;  = 0,249062006 W/m.°C ;  = 0,41498293 W/m.°C ;  = 0,54257923 W/m.°C. On en déduit donc que plus le matériau est compacté, plus sa conductivité thermique augmente.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />
            <a href="#"></a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker3;
