import emailjs from '@emailjs/browser';
import {
  Box,
  Modal, Typography,
} from '@mui/material';
import React, { useState } from 'react';

/** MODAL STYLE */
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '4px solid #d9765f',
  boxShadow: 24,
  p: 4,
};
/* END STYLE MODAL */

const Forms = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [formData, setFormData] = useState({
    to_surname: '',
    to_firstname: '',
    to_email: '',
    number: '',
    object: '',
    infos: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // Sending email

  const serviceId = 'service_ysxyuhj';
  const templateId = 'template_qe1c27d';
  const publicKey = 'vXfX6opNT_VGmXjwx';
    const sendEmail = (e) => {
      e.preventDefault(); // prevents the page from reloading when you hit “Send”

      emailjs.sendForm(serviceId, templateId, e.target, publicKey)
      .then((response) => {
        // console.log(formData);
       console.log('SUCCESS!', response.status, response.text);
       // alert('Inscrtiption validée.');
     }, (error) => {
      // alert('Inscrtiption invalidée.');
         console.log('FAILED...', error);
     });
    };
    /** END FORM FUNCTION */
    return (
      <>
        <section className="contact-section">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="contact--info-area">
                  <h3>Nous rejoindre?</h3>
                  <p>
                    Vous voulez participer à la JSTIM ou devenir partenaire de
                    l'événement?
                  </p>
                  <div className="single-info">
                    <h5>Localisation</h5>
                    <p>
                      <i className="fal fa-home"></i>
                      UAC - Banikoara (Edition 2023)
                      <br />
                      {' '}
                      Bénin
                    </p>
                  </div>
                  <div className="single-info">
                    <h5>Téléphone</h5>
                    <p>
                      <i className="fal fa-phone"></i>
                      (+229) 613 745 00
                    </p>
                  </div>
                  <div className="single-info">
                    <h5>Support</h5>
                    <p>
                      <i className="fal fa-envelope"></i>
                      info@jstim.bj
                    </p>
                  </div>
                  <div className="ab-social">
                    <h5>Follow Us</h5>
                    <a className="fac" href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="twi" href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a className="you" href="#">
                      <i className="fab fa-youtube"></i>
                    </a>
                    <a className="lin" href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="contact-form">
                  <h4> Dîtes-nous</h4>
                  <h6>Veuillez renseigner les informations suivantes.</h6>
                  <form action="#" method="post" className="row" onSubmit={sendEmail}>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="to_name"
                        placeholder="Nom de famille"
                        value={formData.surname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="to_firstname"
                        placeholder="Prénoms"
                        value={formData.firstname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        name="to_email"
                        placeholder="Adresse email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="number"
                        name="number"
                        placeholder="Numéro de Téléphone"
                        value={formData.number}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        name="object"
                        placeholder="Objet de la participation"
                        value={formData.object}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <textarea
                        type="text"
                        name="infos"
                        placeholder="Informations supplémentaires"
                        value={formData.infos}
                        onChange={handleChange}
                        required
                      >
                      </textarea>
                    </div>
                    <div className="col-md-6">
                      <div className="condition-check">
                        <input id="terms-conditions" type="checkbox" required="enable" />
                        <label htmlFor="terms-conditions">
                          J'accepte les
                          {' '}
                          <a href="#">Terms & Conditions</a>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 text-right">
                      <input onClick={handleOpen} className=" btn btn-success" value="Soumettre" type="submit" />
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography id="modal-modal-title" variant="h6" component="h2">
                            Félicitations!!!!
                          </Typography>
                          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Veuillez consulter votre messagerie Gmail.
                          </Typography>
                        </Box>
                      </Modal>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

export default Forms;
