import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker19() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Impact de l'intégration d’une plateforme d'e-learning personnalisée pour les besoins spécifiques des universités sur l'expérience d'apprentissage des étudiants : cas de GestStudent e-learning.</h5>
          <br />
          <p>
            Ce papier examine un progiciel de gestion du système LMD (GestStudent e-learning) conçu pour faciliter l'e-learning au sein des universités. En se concentrant sur les fonctionnalités et l'efficacité de cette solution, l'article explore son intégration réussie dans le contexte académique, mettant en évidence son impact sur l'amélioration de l'expérience d'apprentissage. Ce progiciel couvre la gestion des cours en ligne depuis la création des comptes aux étudiants, la programmation des cours par l’enseignant (catégoriser et gérer selon le système LMD en UE et en EC), la mise à disposition des supports de cours (pdf, vidéos) aux étudiants jusqu’à l’évaluation des étudiants en ligne et l’obtention de leurs notes en passant par la possibilité pour les étudiants de noter les médias et d’évaluer l’enseignement. Les conclusions présentent des perspectives prometteuses pour l'évolution de l'e-learning dans les institutions universitaires grâce à l'utilisation de ce progiciel innovant.
            <br />
            This paper examines an LMD system management software package (GestStudent e-learning) designed to facilitate e-learning within universities. Focusing on the functionality and effectiveness of this solution, the paper explores its successful integration into the academic context, highlighting its impact on improving the learning experience. This software package covers the management of online courses, from the creation of student accounts, the scheduling of courses by the teacher (categorizing and managing them according to the LMD system), the provision of course materials (pdf, videos) to students, through to the online assessment of students and obtaining their scores, via the possibility for students to rate the media and evaluate the teaching. The conclusions present promising prospects for the evolution of e-learning in academic institutions through the use of this innovative software package.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker19;
