import React from 'react';
import singlePost from '../../assets/images/pub1.jpg';

function Blog() {
    return (
      <>
        <div className="single-post-area">
          <p>
            <span role="img" aria-label="">📢/</span>
            {' '}
            𝘼𝙥𝙥𝙚𝙡 à 𝘾𝙤𝙢𝙢𝙪𝙣𝙞𝙘𝙖𝙩𝙞𝙤𝙣 𝙅𝙎𝙏𝙄𝙈 2023 : 𝙋𝙖𝙧𝙩𝙖𝙜𝙚𝙯 𝙑𝙤𝙩𝙧𝙚 𝙀𝙭𝙥𝙚𝙧𝙩𝙞𝙨𝙚
            <br />
            <span role="img" aria-label="">🕑</span>
            {' '}
            14-15 𝘿é𝙘𝙚𝙢𝙗𝙧𝙚 2023, 𝙐𝙣𝙞𝙫𝙚𝙧𝙨𝙞𝙩é 𝙙'𝘼𝙗𝙤𝙢𝙚𝙮 𝘾𝙖𝙡𝙖𝙫𝙞
            <br />
            🎤 𝘝𝘰𝘶𝘴 𝘢𝘷𝘦𝘻 𝘶𝘯𝘦 𝘱𝘢𝘴𝘴𝘪𝘰𝘯 𝘱𝘰𝘶𝘳 𝘭𝘦𝘴 𝘚𝘤𝘪𝘦𝘯𝘤𝘦𝘴, 𝘭𝘢 𝘛𝘦𝘤𝘩𝘯𝘰𝘭𝘰𝘨𝘪𝘦, 𝘭'𝘐𝘯𝘨é𝘯𝘪𝘦𝘳𝘪𝘦 𝘰𝘶 𝘭𝘦𝘴 𝘔𝘢𝘵𝘩é𝘮𝘢𝘵𝘪𝘲𝘶𝘦𝘴 ?
            <br />
            𝘊'𝘦𝘴𝘵 𝘭'𝘰𝘤𝘤𝘢𝘴𝘪𝘰𝘯 𝘥𝘦 𝘤𝘰𝘯𝘵𝘳𝘪𝘣𝘶𝘦𝘳 à 𝘭𝘢 𝘤𝘰𝘯𝘷𝘦𝘳𝘴𝘢𝘵𝘪𝘰𝘯, 𝘥'𝘪𝘯𝘴𝘱𝘪𝘳𝘦𝘳 𝘦𝘵 𝘥'ê𝘵𝘳𝘦 𝘪𝘯𝘴𝘱𝘪𝘳é 𝘱𝘢𝘳 𝘭𝘢 𝘤𝘰𝘮𝘮𝘶𝘯𝘢𝘶𝘵é 𝘥𝘦𝘴 𝘦𝘴𝘱𝘳𝘪𝘵𝘴 𝘣𝘳𝘪𝘭𝘭𝘢𝘯𝘵𝘴 ! 🌟🧠
          </p>
          <div className="post-thumb">
            <img src={singlePost} alt="" />
          </div>
          <h4 className="article-title">Appel à communication</h4>
          <p>
            <span role="img" aria-label="">🕑</span>
            {' '}
            𝑫𝒂𝒕𝒆 : 13 𝒂𝒖 20 𝒅é𝒄𝒆𝒎𝒃𝒓𝒆 2023
            {' '}
            <br />
            <span role="img" aria-label="">🚴‍♂</span>
            𝑳𝒊𝒆𝒖 : 𝑳'𝑼𝒏𝒊𝒗𝒆𝒓𝒔𝒊𝒕é 𝒅'𝑨𝒃𝒐𝒎𝒆𝒚 𝑪𝒂𝒍𝒂𝒗𝒊 & 𝑩𝒂𝒏𝒊𝒌𝒐𝒂𝒓𝒂
            <br />
            <span role="img" aria-label=""> 🔗</span>
            {' '}
            𝘑𝘰𝘪𝘨𝘯𝘦𝘻-𝘷𝘰𝘶𝘴 à 𝘯𝘰𝘶𝘴 𝘱𝘰𝘶𝘳 𝘶𝘯𝘦 𝘴𝘦𝘮𝘢𝘪𝘯𝘦 𝘥'𝘦𝘹𝘱𝘭𝘰𝘳𝘢𝘵𝘪𝘰𝘯, 𝘥𝘦 𝘱𝘢𝘳𝘵𝘢𝘨𝘦 𝘥𝘦 𝘤𝘰𝘯𝘯𝘢𝘪𝘴𝘴𝘢𝘯𝘤𝘦𝘴 𝘦𝘵 𝘥'𝘪𝘯𝘯𝘰𝘷𝘢𝘵𝘪𝘰𝘯.
            𝘌𝘯𝘴𝘦𝘮𝘣𝘭𝘦, 𝘯𝘰𝘶𝘴 𝘤𝘳é𝘰𝘯𝘴 𝘭'𝘢𝘷𝘦𝘯𝘪𝘳 𝘥𝘦 𝘭'é𝘥𝘶𝘤𝘢𝘵𝘪𝘰𝘯 𝘵𝘦𝘤𝘩𝘯𝘰𝘭𝘰𝘨𝘪𝘲𝘶𝘦.
            {' '}
            <br />
          </p>
          <blockquote>
            <p>
              L'éducation est l'arme la plus puissante pour changer le monde.
            </p>
            <cite>Nelson Mandela</cite>
          </blockquote>

          <div className="post-tags">
            <h5>Tags:</h5>
            <a href="#">𝑱𝑺𝑻𝑰𝑴2023</a>
            <a href="#"> É𝒅𝒖𝒄𝒂𝒕𝒊𝒐𝒏𝑻𝒆𝒄𝒉𝒏𝒐𝒍𝒐𝒈𝒊𝒒𝒖𝒆</a>
            <a href="#">𝑰𝒏𝒏𝒐𝒗𝒂𝒕𝒊𝒐𝒏 </a>
            <a href="#"> 𝑰𝑨𝒆𝒕É𝒅𝒖𝒄𝒂𝒕𝒊𝒐𝒏 </a>
            {' '}
            <br />
            <a href="#"> 𝑺𝒐𝒚𝒆𝒛𝑫𝒆𝒔𝑵ô𝒕𝒓𝒆𝒔 </a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Blog;
