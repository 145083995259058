import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker18() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>A Comparative study of Deep Reinforcement learning methods for Cryptocurrency Trading</h5>
          <p>
            Artificial intelligence (AI) has demonstrated remarkable success across various applications. In light of this trend, the field of automated trading has developed a keen interest in leveraging AI techniques to forecast the future prices of financial assets. This interest stems from the need to address trading challenges posed by the inherent volatility and dynamic nature of asset prices. However, crafting a flawless strategy becomes a formidable task when dealing with assets characterized by intricate and ever-changing price dynamics. To surmount these formidable challenges, this research employs an innovative rule-based strategy approach to train Deep Reinforcement Learning (DRL). This application is carried out specifically in the context of trading Bitcoin (BTC) and Ripple (XRP). Our proposed approach hinges on the integration of Deep Q-Network, Double Deep Q-Network, Dueling Double Deep Q-learning networks, alongside the Advantage Actor-Critic algorithms. Each of them aims to yield an optimal policy for our application. To evaluate the effectiveness of our Deep Reinforcement Learning (DRL) approach, we rely on portfolio wealth  and the trade signal as performance metrics. The experimental outcomes highlight that Duelling and Double Deep Q-Network outperformed when using XRP with the increasing of the portfolio wealth.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker18;
