import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker24() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>Génotypage du Big Data d’une Source d’Information</h5>
          <p>
            <br />
            La démographie de la population entraine une grande quantité d’information générée par
            diverses sources. Le secteur de l’éducation est de nos jours une des sources rattrapées par
            l’augmentation exponentielle des données en vitesse et en volume qui sont d’une variété
            importante. La maîtrise de ces flux d’informations nécessite une technologie appropriée,
            nommée ici Big Data, selon ses caractéristiques d’évolution dans le temps. Une étude de ces données massives est nécessaire et d’une importance capitale, pour faire ressortir les différents aspects à prendre en compte sur les plans économique, social et éducatif. Depuis quelques années, le Big Data est d’une tendance prometteuse dans de nombreux secteurs d’activité.
            Toutefois, cette technologie Big Data est complexe lorsqu’on désire établir un compromis
            entre ses différentes caractéristiques. L’objectif de ce travail est de proposer une méthode
            permettant d’attribuer à un Big Data son Génotype. Pour y parvenir, nous avons, avec des
            combinaisons appropriées de mots clés, consulté les bases de données IEEE Xplore,
            ScienceDirect, Springer, Scopus, Arnetminer, arXv, CiteSeerX et la Collection of Computer
            Science Bibliographies pour collecter 120 articles concernant la période 2003 à 2021 suivi de leur traitement. Ensuite, nous avons attribué trois grilles d’échelle (faible, moyenne et fort) qualitative et quantitative des 10 V caractéristiques d’un Big Data. Comme résultat, nous avons abouti à une matrice 3 par 10 donnant lieu à une combinaison de 3 10 soit 59049 génotypes de Big Data.
          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            <a href="#"></a>
            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker24;
