import React from 'react';
// import singlePost from '../../assets/images/pub3.jpg';

function Speaker24() {
    return (
      <>
        <div className="single-post-area mt-20">
          <h5>
            Étude de la mise en place d'un matériau composite à base de sols argileux et de fibres de coque de coco : caractérisation et imagerie par tomographie."
          </h5>
          <br />
          <p>
            L'utilisation des rayons X pour l'imagerie des matériaux offre une perspective approfondie, révélant la structure interne des substances à une échelle microscopique. Cette technique permet une exploration détaillée de la composition, de la densité, et même des éventuelles imperfections invisibles à l'œil nu, fournissant ainsi des informations cruciales pour divers aspects de la recherche scientifique.Cette étude se focalise sur l'analyse des sols argileux et leur propension au retrait-gonflement, en mettant en œuvre des essais géotechniques ainsi que des techniques de tomographie. Au Bénin, la coque de noix de coco, résidu courant de la production de lait de coco, est souvent jetée dans la nature, contribuant ainsi à la pollution environnementale. Les auteurs ont examiné l'influence des fibres de coques de noix de coco sur les caractéristiques géotechniques du sol d'Igana.Les essais physiques classifient le sol comme A-7-5. Les essais mécaniques révèlent qu'avec l'ajout de 1,5% de fibres, la résistance en compression atteint 10,746 MPa à 28 jours. Pour la flexion, l'ajout de 0,5% de fibres conduit à une résistance de 1,52 MPa. Le test CBR atteint sa valeur maximale avec 2% de fibres, tandis que l'essai de cisaillement révèle une cohésion maximale avec 1% de fibres.Enfin, l'imagerie par rayons X du matériau composite réalisée au micro-tomographe dévoile son hétérogénéité, permettant d'affirmer la présence de microfissures invisibles à l'échelle macroscopique.

          </p>

          <div className="post-tags">
            <h5>Keywords</h5>
            <a href="#">RayonsX</a>
            <a href="#">imagerieDesMatériaux</a>
            <a href="#">solsArgileux</a>
            <a href="#">fibresDeCoco</a>
            <a href="#">microfissures</a>

            {' '}
            <br />

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>

      </>
    );
}

export default Speaker24;
