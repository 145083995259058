import React from 'react';
import singlePost from '../../assets/images/pub2.jpg';

function Blog() {
    return (
      <>
        <div className="single-post-area">
          <p>
            <span role="img" aria-label="">🚀</span>
            𝐇𝐚𝐜𝐤𝐚𝐭𝐡𝐨𝐧 𝐉𝐒𝐓𝐈𝐌 𝟐𝟎𝟐𝟑 : 𝐒𝐨𝐲𝐞𝐳 𝐏𝐫ê𝐭𝐬 à 𝐈𝐧𝐧𝐨𝐯𝐞𝐫 !
            <span role="img" aria-label="">🚀</span>
            <br />
            <span role="img" aria-label="">🕑</span>
            14-15 𝐃é𝐜𝐞𝐦𝐛𝐫𝐞 𝟐𝟎𝟐𝟑, 𝐔𝐧𝐢𝐯𝐞𝐫𝐬𝐢𝐭é 𝐝'𝐀𝐛𝐨𝐦𝐞𝐲 𝐂𝐚𝐥𝐚𝐯𝐢
            <br />
            𝘊'𝘦𝘴𝘵 𝘭'𝘢𝘱𝘱𝘦𝘭 𝘢𝘶𝘹 𝘦𝘴𝘱𝘳𝘪𝘵𝘴 𝘤𝘳é𝘢𝘵𝘪𝘧𝘴 𝘦𝘵 𝘱𝘢𝘴𝘴𝘪𝘰𝘯𝘯é𝘴 𝘥𝘦 𝘭𝘢 𝘵𝘦𝘤𝘩𝘯𝘰𝘭𝘰𝘨𝘪𝘦 !
            <br />
            🚀 𝘗𝘢𝘳𝘵𝘪𝘤𝘪𝘱𝘦𝘻 𝘢𝘶 𝘏𝘢𝘤𝘬𝘢𝘵𝘩𝘰𝘯 𝘑𝘚𝘛𝘐𝘔 2023 𝘦𝘵 𝘵𝘳𝘢𝘯𝘴𝘧𝘰𝘳𝘮𝘦𝘻 𝘷𝘰𝘴 𝘪𝘥é𝘦𝘴 𝘪𝘯𝘯𝘰𝘷𝘢𝘯𝘵𝘦𝘴 𝘦𝘯 𝘳é𝘢𝘭𝘪𝘵é.
            <br />
            𝘙𝘦𝘭𝘦𝘷𝘦𝘻 𝘥𝘦𝘴 𝘥é𝘧𝘪𝘴 𝘴𝘵𝘪𝘮𝘶𝘭𝘢𝘯𝘵𝘴 𝘦𝘵 𝘤𝘰𝘯𝘵𝘳𝘪𝘣𝘶𝘦𝘻 à 𝘧𝘢ç𝘰𝘯𝘯𝘦𝘳 𝘭'𝘢𝘷𝘦𝘯𝘪𝘳 𝘥𝘦 𝘭'é𝘥𝘶𝘤𝘢𝘵𝘪𝘰𝘯 𝘵𝘦𝘤𝘩𝘯𝘰𝘭𝘰𝘨𝘪𝘲𝘶𝘦.
            <br />
          </p>
          <div className="post-thumb">
            <img src={singlePost} alt="" />
          </div>
          <h4 className="article-title">Hackathon</h4>
          <p>

            <span role="img" aria-label=""> 🔗</span>
            {' '}
            𝘑𝘰𝘪𝘨𝘯𝘦𝘻-𝘷𝘰𝘶𝘴 à 𝘯𝘰𝘶𝘴 𝘱𝘰𝘶𝘳 𝘶𝘯𝘦 𝘴𝘦𝘮𝘢𝘪𝘯𝘦 𝘥'𝘦𝘹𝘱𝘭𝘰𝘳𝘢𝘵𝘪𝘰𝘯, 𝘥𝘦 𝘱𝘢𝘳𝘵𝘢𝘨𝘦 𝘥𝘦 𝘤𝘰𝘯𝘯𝘢𝘪𝘴𝘴𝘢𝘯𝘤𝘦𝘴 𝘦𝘵 𝘥'𝘪𝘯𝘯𝘰𝘷𝘢𝘵𝘪𝘰𝘯.
            {' '}
            <br />
            𝘌𝘯𝘴𝘦𝘮𝘣𝘭𝘦, 𝘯𝘰𝘶𝘴 𝘤𝘳é𝘰𝘯𝘴 𝘭'𝘢𝘷𝘦𝘯𝘪𝘳 𝘥𝘦 𝘭'é𝘥𝘶𝘤𝘢𝘵𝘪𝘰𝘯 𝘵𝘦𝘤𝘩𝘯𝘰𝘭𝘰𝘨𝘪𝘲𝘶𝘦.
            {' '}
            <br />
          </p>
          <blockquote>
            <p>
              Il n'existe pas d'autre éducation intelligente que d'être soi-même un exemple.
            </p>
            <cite>Albert Einstein</cite>
          </blockquote>

          <div className="post-tags">
            <h5>Tags:</h5>
            <a href="#">𝑱𝑺𝑻𝑰𝑴2023</a>
            <a href="#"> É𝒅𝒖𝒄𝒂𝒕𝒊𝒐𝒏𝑻𝒆𝒄𝒉𝒏𝒐𝒍𝒐𝒈𝒊𝒒𝒖𝒆</a>
            <a href="#">𝑰𝒏𝒏𝒐𝒗𝒂𝒕𝒊𝒐𝒏 </a>
            <a href="#"> 𝑰𝑨𝒆𝒕É𝒅𝒖𝒄𝒂𝒕𝒊𝒐𝒏 </a>
            {' '}
            <br />
            <a href="#"> 𝑺𝒐𝒚𝒆𝒛𝑫𝒆𝒔𝑵ô𝒕𝒓𝒆𝒔 </a>

          </div>
          <div className="post-share">
            <h5>Share:</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="goo" href="#">
              <i className="fab fa-google"></i>
            </a>
          </div>

        </div>
      </>
    );
}

export default Blog;
