import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/full-logo.png';

function FooterHomeEight() {
  return (
    <>
      <section className="appie-footer-area appie-footer-about-area appie-footer-8-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <div className="logo">
                  <a href="#">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <p>La promotion des sciences mathématiques, notre priorité.</p>
                <a href="#">
                  En savoir plus
                  {' '}
                  <i className="fal fa-arrow-right"></i>
                </a>
                <div className="social mt-30">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">JSTIM</h4>
                <ul>
                  <li>
                    <Link to="/">Accueil</Link>
                  </li>
                  <li>
                    <Link to="/programme">Programmmes</Link>
                  </li>
                  <li>
                    <Link to="/activités">Activités</Link>
                  </li>
                  <li>
                    <Link to="/contact">Participation</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Support</h4>
                <ul>
                  <li>
                    <Link to="/">Contact</Link>
                  </li>
                  <li>
                    <Link to="/">Faqs</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Nous joindre</h4>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fal fa-envelope"></i>
                      {' '}
                      info@jstim.bj
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-phone"></i>
                      {' '}
                      +(229) 613 745 00
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-map-marker-alt"></i>
                      {' '}
                      Cotonou, Bénin
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35 "
              >
                <div className="copyright-text">
                  <p>Copyright © iitechBénin All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeEight;
